// export default DeviceTable;
import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

// DeviceEmployeeTable component
const DeviceEmployeeTable = ({ employeeDetails }) => {
    const { t } = useTranslation();
    // Check if employeeDetails is empty
    if (employeeDetails.length === 0) {
        return (
            <div className="table-container w-full">
                <table className="w-full border bg-gray-100">
                    <tbody>
                        <tr className="border-b border-gray-300">
                            <td colSpan="5" className="px-4 py-2 text-center">{t('msg_no_employees_available')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
    
    // Remove duplicates based on employeeId
    const uniqueEmployees = Array.from(new Set(employeeDetails.map(employee => employee.employeeId)))
        .map(employeeId => {
            return employeeDetails.find(employee => employee.employeeId === employeeId);
        });

    return (
        <div className="table-container w-full">
            <table className="w-full border">
                <thead className="w-full bg-gray-100 border border-zinc-200">
                    <tr >
                        <th className="px-3 py-2 bg-gray-200 border" colSpan="5">{t('employees')}</th>
                        
                    </tr>
                    <tr>
                        <th className="px-3 py-2">{t('name')}</th>
                        <th className="px-3 py-2">{t('nickname')}</th>
                        <th className="px-3 py-2">{t('employeeId')}</th>
                        <th className="px-3 py-2">{t('merchantId')}</th>
                        <th className="px-3 py-2">{t('mevCloudDeviceId')}</th>
                    </tr>
                </thead>
                <tbody>
                    {uniqueEmployees.map((employee, index) => (
                        <tr key={index} className="border-b border-gray-300">
                            <td className="px-4 py-2 text-center">{employee.name}</td>
                            <td className="px-4 py-2 text-center">{employee.nickName}</td>
                            <td className="px-4 py-2 text-center">{employee.employeeId}</td>
                            <td className="px-4 py-2 text-center">{employee.merchantId}</td>
                            <td className="px-4 py-2 text-center">{employee.mevCloudDeviceId}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


// Updated DeviceTable component
const DeviceTable = ({ certificates, employees }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'createdDate', direction: 'ascending' });
    const [expandedRow, setExpandedRow] = useState(null);
    const sortedCertificates = React.useMemo(() => {
        if (!certificates) return [];

        const sorted = [...certificates].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        return sorted;
    }, [certificates, sortConfig]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleRowClick = (certificate) => {
        setExpandedRow(expandedRow === certificate.cloverDeviceId ? null : certificate.cloverDeviceId);
    };

    const { t } = useTranslation();

    return (
        <div className="table-container w-full">
            <table className="w-full bg-white border border-zinc-200">
                <thead className="sticky top-0 bg-white">
                    <tr className="bg-white/opacity-0">
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('activation_date')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('certificate_name')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('certified_by')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('clover_device_id')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('clover_merchant_id')}</th>
                        <th
                            className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4"
                            onClick={() => handleSort('createdDate')}
                        >
                            {t('created_date')}
                            {sortConfig.key === 'createdDate' && (
                                sortConfig.direction === 'ascending'
                                    ? <ChevronUpIcon className="w-4 h-4 ml-1" />
                                    : <ChevronDownIcon className="w-4 h-4 ml-1" />
                            )}
                        </th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('device_name')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('expiration_date')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('mev_cloud_device_id')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedCertificates.length > 0 ? (
                        sortedCertificates.map(certificate => {
                            const isExpanded = expandedRow === certificate.cloverDeviceId;
                            const filteredEmployees = employees.filter(employee => employee.cloverDeviceId === certificate.cloverDeviceId);
                            return (
                                <React.Fragment key={certificate.cloverDeviceId}>
                                    <tr className="border-b border-gray-300 cursor-pointer" onClick={() => handleRowClick(certificate)}>
                                        <td className="px-4 py-2 text-center">{certificate.activationDate}</td>
                                        <td className="px-4 py-2 text-center">{certificate.certificateName}</td>
                                        <td className="px-4 py-2 text-center">{certificate.certifiedBy}</td>
                                        <td className="px-4 py-2 text-center">{certificate.cloverDeviceId}</td>
                                        <td className="px-4 py-2 text-center">{certificate.cloverMerchantId}</td>
                                        <td className="px-4 py-2 text-center">{certificate.createdDate}</td>
                                        <td className="px-4 py-2 text-center">{certificate.deviceName}</td>
                                        <td className="px-4 py-2 text-center">{certificate.expirationDate}</td>
                                        <td className="px-4 py-2 text-center">{certificate.mevCloudDeviceId}</td>
                                    </tr>
                                    {isExpanded && (
                                        <tr className="border-b border-gray-300">
                                            <td colSpan={9} className="px-4 py-2">
                                                <DeviceEmployeeTable employeeDetails={filteredEmployees} />
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={9} className="px-4 py-2 text-center">{t('msg_no_certificates_availabe')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default DeviceTable;

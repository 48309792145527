import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doConfirmPasswordReset } from "../../../firebase/auth"; // Adjust the import based on your project structure
import { useTranslation } from 'react-i18next';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const CustomPasswordReset = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [isResetting, setIsResetting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { t } = useTranslation();

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        const oobCode = query.get('oobCode');
        setIsResetting(true);
        try {
            await doConfirmPasswordReset(oobCode, newPassword);
            setSuccessMessage(t('password_reset_successfully'));
            setErrorMessage('');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            setErrorMessage(error.message);
        }
        setIsResetting(false);
    };

    return (

        <div>
            {successMessage ? (
                <div className="w-full h-full relative bg-sky-900 backgroundLoginDark">
                    <div className="w-full sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] h-[263px] p-8 sm:p-10 lg:p-20 xl:p-40 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute bg-white rounded-2xl border border-zinc-200 flex flex-col justify-center items-center gap-6">
                        <div className="h-[103px] pb-6 flex flex-col justify-center items-center gap-2">
                            <div className="self-stretch h-[46px] flex flex-col justify-start items-center gap-2">
                                <div className="text-center text-sky-900 text-lg md:text-[42px] font-bold font-sans leading-[46.20px]">{t('msg_password_reset_link_sent')}</div>
                            </div>
                            <div className="text-center text-zinc-800 text-sm md:text-lg font-normal leading-[25.20px]">{t('msg_check_your_inbox')}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full h-full relative bg-neutral-50 backgroundLogin">
                    <form onSubmit={handlePasswordReset} className="w-full sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] h-[502px] p-8 sm:p-10 lg:p-20 xl:p-40 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute bg-white rounded-2xl border border-zinc-200 flex flex-col justify-center items-center gap-6">
                        <div className="self-stretch h-[70px] pb-6 flex flex-col justify-start items-center gap-2">
                            <div className="self-stretch h-[46px] flex flex-col justify-start items-center gap-2">
                                <div className="text-center text-sky-900 text-lg md:text-[42px] font-bold font-sans leading-[46.20px]">{t('reset_your_password')}</div>
                            </div>
                        </div>
                        <div className="self-stretch h-[76px] flex flex-col justify-start items-start gap-1">
                            <div className="self-stretch h-[76px] flex flex-col justify-start items-start gap-2">
                                <div className="text-sky-900 text-sm md:text-base font-normal leading-tight">{t('enter_new_password')}</div>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder={t('enter_new_password')}
                                    className="h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 w-full" />
                            </div>
                        </div>
                        <div className="self-stretch justify-center items-center gap-4">
                            <div className="h-12 px-3 py-4 bg-sky-900 rounded-lg border-2 border-sky-900 flex justify-center items-center">
                                <button type="submit" disabled={isResetting} className="px-4">
                                    <div className="text-white text-base font-medium tracking-wide">{isResetting ?  t('resetting_password') : t('reset_password')}</div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default CustomPasswordReset;

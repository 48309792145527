import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('fr'); // Default to French  

  const changeLanguage = (lng) => {
    console.log(`Language changed to: ${lng}`);
    i18n.changeLanguage(lng);
    setLanguage(lng); // Update the state to the selected language
  };

  useEffect(() => {
    setLanguage(i18n.language); // Update local state when i18n language changes
  }, [i18n.language]);

  return (
    <div className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
    <button
      className="w-full flex items-center gap-2 text-base font-medium font-['Roboto', 'Arial', 'sans-serif']" // Full width
      onClick={() => {
        const newLang = language === 'en' ? 'fr' : 'en'; // Toggle language
        changeLanguage(newLang);
      }}
    >
      <div className="w-6 h-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
          />
        </svg>
      </div>

      {language === 'fr' ? 'English' : 'Français'}
    </button>
  </div>

  );
};

export default LanguageSwitcher;

import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react';
import Table from '../../home/Table';
import DeviceTable from '../DeviceTable';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Header from "../../header";

import {
    doc,
    query,
    getDocs,
    setDoc,
    collection,
    where,
    Timestamp,
    or,
    and,
    getDoc,
} from 'firebase/firestore';
import db from '../../../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "../../../contexts/authContext";
import { useLocation, useParams, useNavigate } from 'react-router-dom';

function DeviceAdmin() {
    const { currentUser } = useAuth();
    const [selectedCloverIdBool, setSelectedCloverIdBool] = useState(false); // State to manage logout message

    const [selectedCloverId, setselectedCloverId] = useState(''); // State to manage selected MID
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(currentUser !== null);  // New state for logged in message
    const [remainingDays, setRemainingDays] = useState(7);

    const today = new Date();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - today.getDay());

    const [users, setUsers] = useState();
    const [cloverIds, setCloverIds] = useState([]);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date());
    const [lastDayOfMonth, setLastDayOfMonth] = useState(new Date());
    const currentYear = new Date().getFullYear(); // Get the current year
    const [isMobile, setIsMobile] = useState(false);

    const location = useLocation();
    const { cloverMID } = useParams();
    const [data, setData] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [employees, setEmployees] = useState([]);


    const restaurantsData = JSON.parse(localStorage.getItem("mevMandatarysData"))
    const restaurantIndex = restaurantsData.findIndex(item => item.cloverMerchantId === cloverMID);
    const selectedRestaurant = restaurantIndex !== -1 ? restaurantsData[restaurantIndex] : { businessName: '' };
    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')

    if (currentUserRole !== 'admin') {
        navigate('/accessDenied', { replace: true });
    }

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if ( selectedRestaurant.businessName !== "") {
            // document.title = `${selectedRestaurant.businessName}`;
            localStorage.setItem('lastValidPath', window.location.pathname);
        }else{
            navigate('/error404')
        }
    }, [selectedRestaurant, navigate]);

    const getUsers = async () => {
        try {
            let isLoggedIn = false

            const cachedUsersData = localStorage.getItem('lookupData-' + cloverMID);
            const usersData = JSON.parse(cachedUsersData);

            console.log("Fetching logs from Firestore...");

            if (!localStorage.getItem('certificateData-' + cloverMID)) {
                const certificateQuery = query(
                    collection(db, "Certificates"),
                    where('cloverMerchantId', '==', cloverMID)
                );
                const employeeQuery = query(
                    collection(db, "UserAccounts"),
                    where('merchantId', '==', cloverMID)
                );
                try {
                    // Fetch the documents matching the query
                    const certificateQuerySnapshot = await getDocs(certificateQuery);
                    console.log("Certificate fetched from Firestore:", certificateQuerySnapshot.docs.length);
                    const certificateData = certificateQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setCertificates(certificateData);
                    localStorage.setItem('certificateData-' + cloverMID, JSON.stringify(certificateData));

                    const employeeQuerySnapshot = await getDocs(employeeQuery);
                    console.log("Employees fetched from Firestore:", employeeQuerySnapshot.docs.length);
                    const employeeData = employeeQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setEmployees(employeeData);
                    localStorage.setItem('employeeData-' + cloverMID, JSON.stringify(employeeData));

                } catch (error) {
                    console.error("Error fetching logs:", error);
                }

            }
            const uniqueCloverIds = [...new Set(usersData.map(user => user.cloverId))];
            setCloverIds(uniqueCloverIds);

            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {
                    const createdAtTimestamp = user.createdAt.toDate(); // Convert Firestore Timestamp to JavaScript Date object
                    const currentDate = new Date();
                    const diffTime = Math.abs(currentDate - createdAtTimestamp);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    const remainingDays = 7 - diffDays;
                    setRemainingDays(remainingDays);
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        const cachedUsersData = localStorage.getItem('lookupData-' + cloverMID);
        const usersData = JSON.parse(cachedUsersData);

        // Array to store all cloverIds
        const cloverIds = [];

        // Iterate over each object in the array and extract cloverId
        const cachedCertificatesData = localStorage.getItem('certificateData-' + cloverMID);
        const cachedEmployeesData = localStorage.getItem('employeeData-' + cloverMID);


        if (cachedCertificatesData) {
            usersData.forEach(user => {
                cloverIds.push(user.cloverId);
            });
            let isLoggedIn = false

            setCloverIds(cloverIds)
            setUsers(JSON.parse(cachedUsersData));
            setCertificates(JSON.parse(cachedCertificatesData));
            setEmployees(JSON.parse(cachedEmployeesData));


            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {

                    if (user.createdAt && typeof user.createdAt.seconds === 'number') {
                        // Convert seconds to milliseconds and nanoseconds to milliseconds
                        const milliseconds = user.createdAt.seconds * 1000 + Math.floor(user.createdAt.nanoseconds / 1000000);
                        const createdAtDate = new Date(milliseconds);
                        const createdAtTimestamp = createdAtDate; // Convert Firestore Timestamp to JavaScript Date object
                        const currentDate = new Date();
                        const diffTime = Math.abs(currentDate - createdAtTimestamp);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        const remainingDays = 7 - diffDays;
                        setRemainingDays(remainingDays);
                    } else {
                        console.log('Invalid createdAt format');
                    }
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);

        } else {
            getUsers();
        }
    }, []);

    useEffect(() => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setFirstDayOfMonth(firstDay);
        setLastDayOfMonth(lastDay);
    }, []);

    const filteredCertificates = certificates.filter(user => {
        return user
    });

    const filteredEmployees = employees.filter(user => {
        return user
    });

    useEffect(() => {
        // Function to fetch BusinessNames from MEVMandatorys collection
        const fetchBusinessNames = async () => {
            try {
                const mevMandatarysData = localStorage.getItem('mevMandatarysData');
                if (!mevMandatarysData) {
                    console.error('No MEVMandatarys data found in localStorage');
                    return;
                }

                const parsedData = JSON.parse(mevMandatarysData);

                const result = parsedData.filter(item => item.businessName === selectedRestaurant.businessName);

                if (result.length > 0) {
                    const formattedResult = formatDates(result[0]);
                    setData(formattedResult);
                } else {
                    console.error('No matching business name found');
                }
            } catch (error) {
                console.error('Error fetching business names:', error);
            }
        };

        fetchBusinessNames();

        // Clean-up function to unsubscribe when component unmounts
        return () => { };
    }, [selectedRestaurant.businessName]);

    const formatDates = (data) => {
        const formattedData = { ...data };
        if (data.createdDate) {
            formattedData.createdDate = new Date(data.createdDate.seconds * 1000).toLocaleDateString();
        }
        if (data.lastModifiedDate) {
            formattedData.lastModifiedDate = new Date(data.lastModifiedDate.seconds * 1000).toLocaleDateString();
        }
        return formattedData;
    };

    // Your table JSX
    return (
        <Fragment>
            {isMobile && (
                <div className="w-full bg-stone-50 justify-start items-start overflow-x-auto">
                    <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={true} />

                    <div className="w-full p-4 flex flex-col justify-start items-start">
                        <div className="w-full relative">
                            <div className="flex flex-col justify-start items-center gap-2">
                                <div className="text-sky-900 text-lg font-bold">{selectedRestaurant.businessName}</div>
                            </div>
                        </div>

                        <div className="w-full border-b border-gray-400 my-4"></div>

                        <div className="w-full overflow-x-auto" >
                            {filteredCertificates && (
                                <DeviceTable certificates={filteredCertificates}  employees={filteredEmployees} />
                            )}
                        </div>
                    </div>
                </div>

            )}
            {/* Content for desktop view */}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex  overflow-x-auto ">

                        <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={true} />

                        <div className="w-full h-[794px] p-6 flex-col justify-start items-start gap-16 inline-flex " style={{ paddingLeft: '17.5rem' }}>

                            <div className="w-full h-12 relative">
                                <div className="h-[46px] left-0 top-0 absolute flex-col justify-start items-start gap-12 inline-flex">
                                    <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                        <div className="self-stretch text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{selectedRestaurant.businessName}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-[1616px] border-b justify-between items-center inline-flex "></div>

                            <div className=" w-full overflow-x-auto" >
                                {filteredCertificates && (
                                    <DeviceTable certificates={filteredCertificates} employees={filteredEmployees} />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default DeviceAdmin;

import React, { useState, useEffect } from 'react'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/authContext'
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth'
import Header from '../../header'

const Register = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [displayName, setDisplayName] = useState('')

    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { userLoggedIn } = useAuth()
    const [isMobile, setIsMobile] = useState(false);


    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            setIsRegistering(true);
            try {
                const user = await doCreateUserWithEmailAndPassword(email, password, displayName); // Use a temporary password
                setIsRegistering(false);
                navigate('/emailVerification', { state: { email } });
            } catch (error) {
                setErrorMessage(error.message); // Set Firebase error message
                setIsRegistering(false);
            }
        }
    };

    return (
        <>
            {isMobile ? (
                <>
                    <main className="w-full h-full bg-neutral-50">
                        <Header isAdmin={true} />

                        <div className="p-6">
                            <div className="bg-white rounded-2xl border border-zinc-200 flex flex-col justify-center items-center gap-6">
                                <div className="pb-6">
                                    <div className="text-center text-sky-900 text-2xl font-bold">Create New Account</div>
                                </div>
                                <form onSubmit={onSubmit} className="flex flex-col justify-start items-center gap-4">
                                    {/* Input fields */}
                                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                                        <label htmlFor="displayName" className="text-sky-900 text-sm font-normal leading-tight">User Name</label>
                                        <input
                                            type="text"
                                            autoComplete='displayName'
                                            required
                                            value={displayName} onChange={(e) => { setDisplayName(e.target.value) }}
                                            id="displayName"
                                            className="w-full h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300" placeholder="Please enter display name" />
                                    </div>
                                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                                        <label htmlFor="email" className="text-sky-900 text-sm font-normal leading-tight">Email</label>
                                        <input type="email"
                                            autoComplete='email'
                                            required
                                            value={email} onChange={(e) => { setEmail(e.target.value) }}
                                            id="email"
                                            className="w-full h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300" placeholder="Please enter email" />
                                    </div>
                                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                                        <label htmlFor="password" className="text-sky-900 text-sm font-normal leading-tight">Password</label>
                                        <input disabled={isRegistering}
                                            type="password"
                                            autoComplete='new-password'
                                            required
                                            value={password} onChange={(e) => { setPassword(e.target.value) }}
                                            id="password"
                                            className="w-full h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300" placeholder="Please enter password" />
                                    </div>
                                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                                        <label htmlFor="confirmPassword" className="text-sky-900 text-sm font-normal leading-tight">Confirm Password</label>
                                        <input disabled={isRegistering}
                                            type="password"
                                            autoComplete='off'
                                            required
                                            value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                                            id="confirmPassword"
                                            className="w-full h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300" placeholder="Confirm Password" />
                                    </div>
                                    {/* Sign Up button */}
                                    <div className="w-full">
                                        <button
                                            type="submit"
                                            disabled={isRegistering}
                                            className={`w-full h-12 bg-sky-900 rounded-lg border-2 border-sky-900 justify-center items-center flex ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-sky-900 hover:shadow-xl transition duration-300'}`}
                                        >
                                            <span className="text-white text-base font-medium leading-none tracking-wide">{isRegistering ? 'Signing Up...' : 'Sign Up'}</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>

                </>
            ) : (
                <>
                    <main className="w-[1920px] h-[1080px] relative bg-neutral-50 backgroundLogin">
                        <Header isAdmin={true} />

                        <div className="h-[646px] p-20 left-[620px] top-[178px] bg-white rounded-2xl border border-zinc-200 flex-col justify-center items-center gap-6 inline-flex">
                            <div className="self-stretch h-[70px] pb-6 flex-col justify-start items-center gap-2 flex">
                                <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                    <div className="self-stretch text-center text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">Create New Account</div>
                                </div>
                            </div>
                            <form
                                onSubmit={onSubmit} className="self-stretch h-[324px] flex-col justify-start items-center gap-4 flex">
                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                    <label htmlFor="displayName" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">User Name</label>
                                    <input
                                        type="text"
                                        autoComplete='displayName'
                                        required
                                        value={displayName} onChange={(e) => { setDisplayName(e.target.value) }}
                                        id="displayName"
                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder="Please enter display name" />
                                </div>
                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                    <label htmlFor="email" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Email</label>
                                    <input type="email"
                                        autoComplete='email'
                                        required
                                        value={email} onChange={(e) => { setEmail(e.target.value) }}
                                        id="email"
                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder="Please enter email" />
                                </div>
                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                    <label htmlFor="password" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Password</label>
                                    <input disabled={isRegistering}
                                        type="password"
                                        autoComplete='new-password'
                                        required
                                        value={password} onChange={(e) => { setPassword(e.target.value) }}
                                        id="password"
                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder="Please enter password" />
                                </div>
                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                    <label htmlFor="confirmPassword" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Confirm Password</label>
                                    <input disabled={isRegistering}
                                        type="password"
                                        autoComplete='off'
                                        required
                                        value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                                        id="confirmPassword"
                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder="Confirm Password" />
                                </div>
                                <div className="self-stretch justify-center items-center gap-4 inline-flex">

                                    <button
                                        type="submit"
                                        disabled={isRegistering}
                                        className={`grow shrink basis-0 h-12 px-3 py-4 bg-sky-900 rounded-lg border-2 border-sky-900 justify-center items-center flex ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-sky-900 hover:shadow-xl transition duration-300'}`}
                                    >
                                        <span className="text-white text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{isRegistering ? 'Signing Up...' : 'Sign Up'}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </main></>
            )}
        </>
    )
}

export default Register

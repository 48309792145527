import React, { useState } from 'react';
import {
    doc,
    updateDoc,
} from 'firebase/firestore';
import db from '../../firebase/firebase';
import { useTranslation } from 'react-i18next';

const WebUserTable = ({ users, cloverMID }) => {
    const [filteredUsers, setFilteredUsers] = useState([]);
    React.useEffect(() => {
        if (users && users.length > 0) {
            setFilteredUsers(users.filter(user => user.merchantId === cloverMID));
        }
    }, [users, cloverMID]);

    const { t } = useTranslation();

    return (
        <div className="table-container w-full">
            <table className="w-full bg-white border border-zinc-200">
                <thead className="sticky top-0 bg-white">
                    <tr className="bg-white/opacity-0">
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('first_name')}</th>
                        {/* <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('last_name')}</th> */}
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('email')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">UID</th>
                        {/* <th className="grow shrink basis-0 bg-slate-100 border-t border-zinc-200 px-3 py-4"></th> */}
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers && filteredUsers.length > 0 ? (
                        filteredUsers.map(user => {
                            return (
                                <tr key={user.id} className="border-b border-gray-300">
                                    <td className="px-4 py-2 text-center">{user.firstname}</td>
                                    {/* <td className="px-4 py-2 text-center">{user.nickName}</td> */}
                                    <td className="px-4 py-2 text-center">{user.email}</td>
                                    <td className="px-4 py-2 text-center">{user.uid}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={10} className="px-4 py-2 text-center">{t('msg_no_users_available')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default WebUserTable;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../../firebase/auth"; // Adjust the import based on your project structure
import { useTranslation } from 'react-i18next';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const VerifyingEmail = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const [isVerifying, setIsVerifying] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const oobCode = query.get('oobCode');
        if (oobCode) {
            verifyEmail(oobCode)
                .then(() => {
                    setSuccessMessage(t('email_verification_successful'));
                    // setTimeout(() => {
                    //     navigate('/login');
                    // }, 3000);
                })
                .catch((error) => {
                    setErrorMessage(error.message);
                })
                .finally(() => {
                    setIsVerifying(false);
                });
        } else {
            setIsVerifying(false);
            setErrorMessage(t('invalid_verification_code'));
        }
    }, [query, navigate]);

    const handleContinue = () => {
        navigate('/login');
    };

    return (
        <div>
            {isVerifying ? (
                <p>{t('verifying_email')}</p>
            ) : successMessage ? (

                <div>
                    <div className="w-[1920px] h-[1080px] relative bg-sky-900 backgroundLoginDark">
                        <div className="h-[335px] p-20 left-[620px] top-[362px] absolute bg-white rounded-2xl border border-zinc-200 flex-col justify-center items-center gap-6 inline-flex">
                            <div className="h-[103px] pb-6 flex-col justify-start items-center gap-2 flex">
                                <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                    <div className="self-stretch text-center text-sky-900 text-[42px] font-bold font-['Roboto'] leading-[46.20px]">{t('msg_email_verified')}</div>
                                </div>
                                <div className="self-stretch text-center text-zinc-800 text-lg font-normal font-['Roboto'] leading-[25.20px]">{t('msg_sign_in_account')}</div>
                            </div>
                            <div className="self-stretch justify-center items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 h-12 px-3 py-4 bg-sky-900 rounded-lg border-2 border-sky-900 justify-center items-center flex">
                                    <button onClick={handleContinue} className="px-4 justify-center items-center gap-2.5 flex">
                                        <div className="text-white text-base font-medium font-['Roboto'] leading-none tracking-wide">{t('continue')}</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <p>{successMessage}</p> */}
                    {/* <button onClick={handleContinue}>Continue to Login</button> */}
                </div>
            ) : (
                <div>
                    <p>{errorMessage}</p>
                    <button onClick={handleContinue}>{t('continue_to_login')}</button>
                </div>
            )}
        </div>
    );
};

export default VerifyingEmail;

import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  confirmPasswordReset,
  applyActionCode ,
  fetchSignInMethodsForEmail,
  updateProfile,
  getUserByEmail,
} from "firebase/auth";

export const doCreateUserWithEmailAndPassword = async (email, password, displayName) => {
  try {
    // Creating user with email and password
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(userCredential.user, {
      displayName: displayName
    });
    // Sending email verification
    // await sendEmailVerification(userCredential.user, {
    //   url: `${window.location.origin}/emailVerified`
      
    // });
    await sendEmailVerification(userCredential.user);

    return userCredential.user;
  } catch (error) {
    throw error; // Propagate Firebase error
  }
};


export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    if (!userCredential.user.emailVerified) {
      throw new Error("You must verify your email before signing in.");
    }
    return userCredential.user;

  } catch (error) {
    throw error;
  }
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  // add user to firestore
  return user;
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doConfirmPasswordReset = (oobCode, newPassword) => {
  return confirmPasswordReset(auth, oobCode, newPassword);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not found');
  }

  try {
    console.log('Verification email sent successfully ok1');

    await sendEmailVerification(user, {
      url: `${window.location.origin}/login`,
    });
    console.log('Verification email sent successfully ok');
  } catch (error) {
    if (error.code === 'auth/too-many-requests') {
      // Retry the operation after a delay
      await new Promise(resolve => setTimeout(resolve, 5000)); // 5-second delay
      return await doSendEmailVerification(); // Retry the operation recursively
    } else {
      console.error('Error sending verification email:', error);
      throw error; // Re-throw the error if it's not due to rate limiting
    }
  }
};

export const verifyEmail = (oobCode) => {
  return applyActionCode(auth, oobCode);
};

export const getVerifiedEmail = async () => {
  try {
    const user = auth.currentUser; // Wait for the current user to be fetched
    if (!user) {
      throw new Error('User not found');
    }

    // Now that we have the user, proceed with email verification
    if (user.emailVerified) {
      console.log("Email is verified");
      return true;
    } else {
      console.log("Email is not verified");
      return false;
    }
  } catch (error) {
    throw error; // Re-throw the error if it's not due to rate limiting
  }
};

// export const checkEmailExists = async (email) => {
//   try {
//     const methods =  fetchSignInMethodsForEmail(auth, email);
//     // If methods array is empty, the email doesn't exist
//     return methods.length > 0;
//   } catch (error) {
//     console.error('Error checking email existence:', error);
//     // Log the error object for debugging
//     console.error(error);
//     throw new Error("Error checking email existence");
//   }
// };

// export const checkEmailExists = async (email) => {
//   const userRecord = await getUserByEmail(auth, email);
//   return userRecord.uid;
// };


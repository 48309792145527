
import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react';
import {
    doc,
    query,
    getDocs,
    setDoc,
    collection,
    where,
    serverTimestamp,
} from 'firebase/firestore';
import db from '../../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "../../contexts/authContext";
import Header from "../header";
import { useParams, useNavigate } from 'react-router-dom';
import WebUserTable from './WebUserTable';
import { doCreateUserWithEmailAndPassword } from '../../firebase/auth'
import { useTranslation } from 'react-i18next';

function Home() {
    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')
    if (currentUserRole !== 'admin') {
        navigate('/accessDenied', { replace: true });
    }
    const { currentUser } = useAuth();
    const { cloverMID } = useParams();
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [store, setStore] = useState('');
    const [ownerEmail, setEmail] = useState('');
    const [cloverId, setCloverId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [merchandId, setMerchandId] = useState('');
    const [address, setAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(currentUser !== null);  // New state for logged in message
    const [remainingDays, setRemainingDays] = useState(7); // Initialize remaining days to 6
    const [users, setUsers] = useState();
    const [cloverIds, setCloverIds] = useState([]);
    const [showLogoutMessage, setShowLogoutMessage] = useState(false); // State to manage logout message
    const [showMessage, setShowMessage] = useState(true); // State to control the visibility of the message
    const [isMobile, setIsMobile] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [emailRegister, setEmailRegister] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const restaurantsData = JSON.parse(localStorage.getItem("mevMandatarysData"))
    const restaurantIndex = restaurantsData.findIndex(item => item.cloverMerchantId === cloverMID);
    const selectedRestaurant = restaurantIndex !== -1 ? restaurantsData[restaurantIndex] : { businessName: '' };
    const { t } = useTranslation();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            setIsRegistering(true);
            try {
                const user = await doCreateUserWithEmailAndPassword(emailRegister, password, displayName); // Use a temporary password
                const name = user.displayName;
                const email = user.email;
                const uidNo = user.uid

                const newUser = {
                    firstname: name,
                    id: uuidv4(),
                    email: email,
                    uid: uidNo,
                    role: "",
                    merchantId: cloverMID,

                };

                const userRef = doc(collection(db, "Users"), newUser.id);
                await setDoc(userRef, newUser);
                setShowMessage(true);
                setShowLogoutMessage(true); // Set the flag to true when the user is added successfully
                //   window.location.reload();

                setIsRegistering(false);
                navigate('/emailVerification', { state: { emailRegister } });
            } catch (error) {
                setErrorMessage(error.message); // Set Firebase error message
                setIsRegistering(false);
            }
        }
    };

    useEffect(() => {
        if (selectedRestaurant.businessName !== "") {
            // document.title = `${selectedRestaurant.businessName}`;
            localStorage.setItem('lastValidPath', window.location.pathname);
        } else {
            navigate('/error404')
        }
    }, [selectedRestaurant, navigate]);

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCloseClick = () => {
        setShowMessage(false); // Set showMessage to false to hide the message
    };

    useEffect(() => {
        const cachedUsersData = localStorage.getItem('lookupData-' + cloverMID);
        const usersData = JSON.parse(cachedUsersData);

        // Array to store all cloverIds
        const cloverIds = [];
        if (cachedUsersData) {
            usersData.forEach(user => {
                cloverIds.push(user.cloverId);
            });
            const filteredUsersData = usersData.filter(user => user.merchantId === cloverMID);
            setCloverIds(cloverIds)
            setUsers(filteredUsersData);
            let isLoggedIn = false
            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {
                    if (user.createdAt && typeof user.createdAt.seconds === 'number') {
                        // Convert seconds to milliseconds and nanoseconds to milliseconds
                        const milliseconds = user.createdAt.seconds * 1000 + Math.floor(user.createdAt.nanoseconds / 1000000);
                        const createdAtDate = new Date(milliseconds);
                        const createdAtTimestamp = createdAtDate; // Convert Firestore Timestamp to JavaScript Date object
                        const currentDate = new Date();
                        const diffTime = Math.abs(currentDate - createdAtTimestamp);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        const remainingDays = 7 - diffDays;
                        setRemainingDays(remainingDays);
                    } else {
                        console.log('Invalid createdAt format');
                    }
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);

        }
    }, []);

    //Funtion to add user Form
    async function addUser() {
        const status = 'Active';
        const store = selectedRestaurant.businessName;
        const cloverId = cloverMID
        // Check if any of the required fields are empty
        if (!firstname || !lastname || !employeeId || !ownerEmail) {
            setShowMessage(true);
            setErrorMessage('Please fill in all fields.');
            return;
        }
        try {
            const transactionsQuery = query(
                collection(db, "Users"),
                where('email', '==', ownerEmail)
            );
            const transactionsQuerySnapshot = await getDocs(transactionsQuery);
            console.log("Users fetched from Firestore:", transactionsQuerySnapshot.docs.length);
            const uidData = transactionsQuerySnapshot.docs.map(doc => doc.data().uid);

            if (transactionsQuerySnapshot.docs.length < 1) {
                setShowMessage(true);
                setErrorMessage('This email does not exist, please register the user before adding him to a restaurant');
                return;
            }

            const newUser = {
                firstname,
                lastname,
                store,
                cloverId,
                employeeId,
                id: uuidv4(),
                owner: uidData[0],
                ownerEmail,
                status,
                createdAt: serverTimestamp(),
                lastUpdate: serverTimestamp(),
            };
            const lookupData = query(
                collection(db, "Lookup"),
                where('ownerEmail', '!=', newUser.ownerEmail),
                where('cloverId', '==', cloverMID)
            );
            const lookupDataQuery = await getDocs(lookupData);
            console.log("Lookup fetched from Firestore:", lookupDataQuery.docs.length);

            if (lookupDataQuery.docs.length < 1) {
                setShowMessage(true);
                setErrorMessage('This email already exists for this restaurant');
                return;
            } else {
                const userRef = doc(collection(db, "Lookup"), newUser.id);
                await setDoc(userRef, newUser);
                setErrorMessage(false)
                setShowMessage(true);
                setShowLogoutMessage(true); // Set the flag to true when the user is added successfully
            }

        } catch (error) {
            if (error === "Error checking email existence") {
                console.error('Error adding user:', error);
                setShowMessage(true);
                setErrorMessage('An error occurred while adding the user.');
            } else {
                console.error('Error', error);

            }
        }
    }

    // HTML
    return (
        <Fragment>
            {isMobile && (
                <div className="w-full bg-stone-50 justify-start items-start">
                    <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={true} />

                    <div className="w-full p-4 flex flex-col justify-start items-start">
                        <div className="w-full flex justify-start items-center gap-4">
                            <div className="flex-grow flex flex-col justify-start items-start gap-6" style={{ paddingX: '1.75rem' }}>
                                <div className="w-full relative">
                                    <div className="flex flex-col justify-start items-center gap-2">
                                        <div className="text-sky-900 text-lg font-bold">{selectedRestaurant.businessName}</div>
                                    </div>
                                </div>
                                <div className="flex self-stretch justify-start items-center gap-6">
                                    <div className="flex justify-center items-center gap-4">
                                        {/* <button className="h-10 px-4 rounded-lg border-2 border-sky-900 justify-center items-center flex" onClick={() => setShowForm(!showForm)}>
                                            <div className="px-4 justify-center items-center gap-2.5 flex">
                                                <div className="text-sky-900 text-base font-medium leading-none tracking-wide">Add User</div>
                                            </div>
                                            <div className="px-1 justify-center items-center flex">
                                                <div className="text-sky-900 text-xl font-medium leading-7">+</div>
                                            </div>
                                        </button> */}
                                        <button className="h-10 px-4 rounded-lg border-2 border-sky-900 justify-center items-center flex" onClick={() => setShowRegister(!showRegister)}>
                                            <div className="px-4 justify-center items-center gap-2.5 flex">
                                                <div className="text-sky-900 text-base font-medium leading-none tracking-wide">{t('register_user')}</div>
                                            </div>
                                            <div className="px-1 justify-center items-center flex">
                                                <div className="text-sky-900 text-xl font-medium leading-7">+</div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                {showForm && (
                                    <div className="w-full p-4 bg-white border border-gray-200 rounded-lg mt-6">
                                        <h2 className="text-xl font-bold mb-4">Add new User for {selectedRestaurant.businessName}</h2>
                                        <div className="flex flex-col gap-4">
                                            <input
                                                id="firstname"
                                                type="text"
                                                value={firstname}
                                                onChange={(e) => setFirstname(e.target.value)}
                                                placeholder={t('enter_firstname')}
                                                required
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />
                                            <input
                                                id="lastname"
                                                type="text"
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                                placeholder={t('enter_lastname')}
                                                required
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />
                                            <input
                                                id="email"
                                                type="text"
                                                value={ownerEmail}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder={t('enter_user_email_name')}
                                                required
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />
                                            <input
                                                id="employeeId"
                                                type="text"
                                                value={employeeId}
                                                onChange={(e) => setEmployeeId(e.target.value)}
                                                placeholder='Enter Employee Id'
                                                required
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />
                                            <input
                                                id="store"
                                                type="text"
                                                value={store}
                                                placeholder={selectedRestaurant.businessName}
                                                disabled={true}
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />
                                            <input
                                                id="cloverId"
                                                type="text"
                                                value={cloverId}
                                                placeholder={cloverMID}
                                                disabled={true}
                                                required
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />
                                            <input
                                                id="Address"
                                                type="dropdown"
                                                value={address}
                                                placeholder={`${selectedRestaurant.street1}, ${selectedRestaurant.street2}, ${selectedRestaurant.city}, ${selectedRestaurant.province}, ${selectedRestaurant.postalCode}`}
                                                disabled={true}
                                                required
                                                className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                            />

                                            <button onClick={() => addUser()} className="h-12 px-4 py-3 bg-sky-900 rounded-lg text-white text-base font-medium">{t('submit')}</button>
                                        </div>
                                        {showMessage && (
                                            <div className="w-full p-4 bg-green-100 border-l-4 border-green-500 mt-6 flex justify-start items-center gap-4">
                                                <div className="w-6">
                                                    {/* Icon */}
                                                </div>
                                                <div className="flex-grow">
                                                    {errorMessage ? (
                                                        <div className="text-red-500 font-semibold">{errorMessage}</div>
                                                    ) : showLogoutMessage ? (
                                                        <div className="text-green-500 font-semibold">Successfully Added. Please log out and relog in to see new additions.</div>
                                                    ) : (
                                                        <div className="text-blue-500 font-semibold">Please fill the form</div>
                                                    )}
                                                </div>
                                                <div className="w-6">
                                                    {/* Close icon */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {showRegister && (
                                    <>
                                        <div className="h-[646px] p-20 left-[620px] top-[178px] bg-white rounded-2xl border border-zinc-200 flex-col justify-center items-center gap-6 inline-flex">
                                            <div className="self-stretch h-[70px] pb-6 flex-col justify-start items-center gap-2 flex">
                                                <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                                    <div className="self-stretch text-center text-sky-900 text-[30px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{t('create_new_account')}</div>
                                                </div>
                                            </div>
                                            <form
                                                onSubmit={onSubmit} className="self-stretch h-[324px] flex-col justify-start items-center gap-4 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                    <label htmlFor="displayName" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('user_name')}</label>
                                                    <input
                                                        type="text"
                                                        autoComplete='displayName'
                                                        required
                                                        value={displayName} onChange={(e) => { setDisplayName(e.target.value) }}
                                                        id="displayName"
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('msg_enter_display_name')} />
                                                </div>
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                    <label htmlFor="emailRegister" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('email')}</label>
                                                    <input type="email"
                                                        autoComplete='email'
                                                        required
                                                        value={emailRegister} onChange={(e) => { setEmailRegister(e.target.value) }}
                                                        id="emailRegister"
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('msg_enter_email')} />
                                                </div>
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                    <label htmlFor="password" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('password')}</label>
                                                    <input disabled={isRegistering}
                                                        type="password"
                                                        autoComplete='new-password'
                                                        required
                                                        value={password} onChange={(e) => { setPassword(e.target.value) }}
                                                        id="password"
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('msg_enter_password')} />
                                                </div>
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                    <label htmlFor="confirmPassword" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('confirm_password')}</label>
                                                    <input disabled={isRegistering}
                                                        type="password"
                                                        autoComplete='off'
                                                        required
                                                        value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                                                        id="confirmPassword"
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('confirm_password')} />
                                                </div>
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                    <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                        <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Employee Id</div>
                                                        <input
                                                            id="cloverId"
                                                            type="text"
                                                            value={cloverId}
                                                            placeholder={cloverMID}
                                                            disabled={true}
                                                            required
                                                            className="h-12 px-4 py-3 bg-gray-100 border-b border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="self-stretch justify-center items-center gap-4 inline-flex">
                                                    <button
                                                        type="submit"
                                                        disabled={isRegistering}
                                                        className={`grow shrink basis-0 h-12 px-3 py-4 bg-sky-900 rounded-lg border-2 border-sky-900 justify-center items-center flex ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-sky-900 hover:shadow-xl transition duration-300'}`}
                                                    >
                                                        <span className="text-white text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{isRegistering ? t('signing_up') : t('sign_up')}</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-full overflow-x-auto mt-4">
                            <WebUserTable users={users} cloverMID={cloverMID} />
                        </div>
                    </div>
                </div>

            )}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex overflow-x-auto">
                        <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={true} />

                        <div className="w-full flex-col justify-start items-start gap-16 inline-flex" style={{ paddingLeft: '17.5rem' }}>
                            <div className="self-stretch h-[118px] pl-[17px] pr-[49px] justify-start items-center gap-6 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                    <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                                        <div className="w-full h-12 relative">
                                            <div className="h-[46px] left-0 top-0 absolute flex-col justify-start items-start gap-12 inline-flex">
                                                <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                                    <div className="self-stretch text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{selectedRestaurant.businessName}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[371px] h-12 justify-center items-center gap-4 flex">
                                    {/* <div className="h-12 justify-center items-center gap-4 flex">
                                        <button
                                            className="grow shrink basis-0 h-12 justify-center items-center gap-4 flex"
                                            onClick={() => setShowForm(!showForm)}
                                        >
                                            <div className="grow shrink basis-0 h-12 px-3 py-4 rounded-lg border-2 border-sky-900 justify-center items-center flex">
                                                <div className="px-4 justify-center items-center gap-2.5 flex">
                                                    <div className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">Add User</div>
                                                </div>
                                                <div className="px-1 justify-center items-center flex">
                                                    <div className="text-sky-900 text-[28.09px] font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-7 tracking-wide">+</div>
                                                </div>
                                            </div>
                                        </button>
                                    </div> */}
                                    <div className="h-12 justify-center items-center gap-4 flex">
                                        <button
                                            className="grow shrink basis-0 h-12 justify-center items-center gap-4 flex"
                                            onClick={() => setShowRegister(!showRegister)}
                                        >
                                            <div className="grow shrink basis-0 h-12 px-3 py-4 rounded-lg border-2 border-sky-900 justify-center items-center flex">
                                                <div className="px-4 justify-center items-center gap-2.5 flex">
                                                    <div className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{t('register_user')}</div>
                                                </div>
                                                <div className="px-1 justify-center items-center flex">
                                                    <div className="text-sky-900 text-[28.09px] font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-7 tracking-wide">+</div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full border-b justify-between items-center inline-flex"></div>

                            {showForm && (
                                <>
                                    <div className="h-[1130px] p-20 left-[620px] top-[178px] bg-white rounded-2xl border border-zinc-200 flex-col justify-center items-center gap-6 inline-flex">

                                        <div className="self-stretch h-5 flex-col justify-center items-start gap-4 flex">
                                            <div className="text-zinc-800 text-lg font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Add new User for {selectedRestaurant.businessName}</div>
                                        </div>

                                        <div className="w-[616px] flex-col justify-start items-start gap-4 flex">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                                                    <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                        <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">First Name</div>
                                                        <input
                                                            id="firstname"
                                                            type="text"
                                                            value={firstname}
                                                            onChange={(e) => setFirstname(e.target.value)}
                                                            placeholder={t('enter_firstname')}
                                                            required
                                                            className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Last Name</div>
                                                    <input
                                                        id="lastname"
                                                        type="text"
                                                        value={lastname}
                                                        onChange={(e) => setLastname(e.target.value)}
                                                        placeholder={t('enter_lastname')}
                                                        required
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                    />
                                                </div>
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">User Email</div>
                                                    <input
                                                        id="email"
                                                        type="text"
                                                        value={ownerEmail}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder={t('enter_user_email_name')}
                                                        required
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                    />
                                                </div>
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Employee Id</div>
                                                    <input
                                                        id="employeeId"
                                                        type="text"
                                                        value={employeeId}
                                                        onChange={(e) => setEmployeeId(e.target.value)}
                                                        placeholder='Enter EmployeeId'
                                                        required
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                    />
                                                </div>
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Store Name</div>
                                                    <input
                                                        id="store"
                                                        type="text"
                                                        value={store}
                                                        // onChange={(e) => setStore(e.target.value)}
                                                        placeholder={selectedRestaurant.businessName}
                                                        disabled={true}
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                    />
                                                </div>
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Clover ID</div>
                                                    <input
                                                        id="cloverId"
                                                        type="text"
                                                        value={cloverId}
                                                        placeholder={cloverMID}
                                                        disabled={true}
                                                        required
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                    />
                                                </div>
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <div className="self-stretch h-[76px] flex-col justify-start items-start gap-2 flex">
                                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Address</div>
                                                    <input
                                                        id="address"
                                                        type="text"
                                                        value={address}
                                                        placeholder={`${selectedRestaurant.street1}, ${selectedRestaurant.street2}, ${selectedRestaurant.city}, ${selectedRestaurant.province}, ${selectedRestaurant.postalCode}`}
                                                        disabled={true}
                                                        required
                                                        className="self-stretch h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 inline-flex"
                                                    />
                                                </div>
                                            </div>
                                            <div className="self-stretch justify-end items-center gap-4 inline-flex">
                                                <button onClick={() => addUser()} className="grow shrink basis-0 h-12 px-3 py-4 bg-sky-900 border-2 border-blue-600 justify-center items-center flex text-white text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">
                                                    {t('submit')}
                                                </button>
                                            </div>
                                            {showMessage && (
                                                <div className="w-full h-12 pl-4 bg-green-600/opacity-10 border-l-2 border-green-600 flex-col justify-start items-start flex mt-6">
                                                    <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                        <div className="w-6 justify-center items-center gap-2.5 flex">
                                                            <div className="w-6 h-6 relative" />
                                                        </div>

                                                        {errorMessage ? (
                                                            <div className="grow shrink basis-0 pt-3.5 flex-col justify-start items-start inline-flex">
                                                                <div className="self-stretch justify-start items-center gap-1 inline-flex">
                                                                    <div className="text-zinc-800 text-sm font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">{errorMessage}</div>
                                                                    <div className="grow shrink basis-0 text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight"></div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            showLogoutMessage ? (
                                                                <div className="grow shrink basis-0 pt-3.5 flex-col justify-start items-start inline-flex">
                                                                    <div className="self-stretch justify-start items-center gap-1 inline-flex">
                                                                        <div className="text-zinc-800 text-sm font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">Successfully Added.</div>
                                                                        <div className="grow shrink basis-0 text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Please log out and relog in to see new additions.</div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="grow shrink basis-0 pt-3.5 flex-col justify-start items-start inline-flex">
                                                                    <div className="self-stretch justify-start items-center gap-1 inline-flex">
                                                                        <div className="text-zinc-800 text-sm font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none"></div>
                                                                        <div className="grow shrink basis-0 text-zinc-800 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">Please fill the form</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                        <div className="w-12 justify-start items-center flex">
                                                            <div className="w-12 h-12 justify-center items-center gap-2.5 flex">
                                                                <div className="w-6 h-6 relative logoClose" onClick={handleCloseClick} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {showRegister && (
                                <>
                                    <div className="h-[646px] p-20 left-[620px] top-[178px] bg-white rounded-2xl border border-zinc-200 flex-col justify-center items-center gap-6 inline-flex">
                                        <div className="self-stretch h-[70px] pb-6 flex-col justify-start items-center gap-2 flex">
                                            <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                                <div className="self-stretch text-center text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{t('create_new_account')}</div>
                                            </div>
                                        </div>
                                        <form
                                            onSubmit={onSubmit} className="self-stretch h-[324px] flex-col justify-start items-center gap-4 flex">
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <label htmlFor="displayName" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('user_name')}</label>
                                                <input
                                                    type="text"
                                                    autoComplete='displayName'
                                                    required
                                                    value={displayName} onChange={(e) => { setDisplayName(e.target.value) }}
                                                    id="displayName"
                                                    className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('msg_enter_display_name')} />
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <label htmlFor="emailRegister" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('email')}</label>
                                                <input type="email"
                                                    autoComplete='email'
                                                    required
                                                    value={emailRegister} onChange={(e) => { setEmailRegister(e.target.value) }}
                                                    id="emailRegister"
                                                    className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('msg_enter_email')} />
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <label htmlFor="password" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('password')}</label>
                                                <input disabled={isRegistering}
                                                    type="password"
                                                    autoComplete='new-password'
                                                    required
                                                    value={password} onChange={(e) => { setPassword(e.target.value) }}
                                                    id="password"
                                                    className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('msg_enter_password')} />
                                            </div>
                                            <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                                                <label htmlFor="confirmPassword" className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('confirm_password')}</label>
                                                <input disabled={isRegistering}
                                                    type="password"
                                                    autoComplete='off'
                                                    required
                                                    value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                                                    id="confirmPassword"
                                                    className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex" placeholder={t('confirm_password')} />
                                            </div>

                                            <div className="self-stretch justify-center items-center gap-4 inline-flex">
                                                <button
                                                    type="submit"
                                                    disabled={isRegistering}
                                                    className={`grow shrink basis-0 h-12 px-3 py-4 bg-sky-900 rounded-lg border-2 border-sky-900 justify-center items-center flex ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-sky-900 hover:shadow-xl transition duration-300'}`}
                                                >
                                                    <span className="text-white text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{isRegistering ? t('signing_up') : t('sign_up')}</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            )}
                            <div className="w-full overflow-x-auto">
                                <WebUserTable users={users} cloverMID={cloverMID} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default Home;

import React, { useState } from "react";
import { doPasswordReset } from "../../../firebase/auth";
import { useAuth } from "../../../contexts/authContext";
import { useTranslation } from 'react-i18next';

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const [isResetting, setIsResetting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const handleReset = async (e) => {
        e.preventDefault();
        setIsResetting(true);
        try {
            await doPasswordReset(email);
            setResetSent(true);
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(error.message);
        }
        setIsResetting(false);
    };


    const goBack = () => {
        window.history.back();
    };

    return (
        <div>
            {resetSent ? (
                <>
                    <div className="w-full h-full relative bg-sky-900 backgroundLoginDark">
                        <div className="w-[80%] sm:w-[70%] lg:w-[60%] xl:w-[50%] h-[263px] p-20 sm:p-10 lg:p-20 xl:p-40 absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-2xl border border-zinc-200 flex flex-col justify-center items-center gap-6">
                            <div className="h-[103px] pb-6 flex flex-col justify-center items-center gap-2">
                                <div className="text-center text-sky-900 text-[42px] font-bold leading-[46.20px] sm:text-2xl lg:text-3xl xl:text-4xl">{t('msg_reset_email_link_sent')}</div>
                                <div className="text-center text-zinc-800 text-lg font-normal leading-[25.20px] sm:text-base lg:text-lg xl:text-xl">{t('msg_check_your_inbox')}</div>

                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full min-h-screen flex flex-col justify-center items-center bg-neutral-50 backgroundLogin">
                    <div className="w-full sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl bg-white">
                        <div className="self-stretch h-[70px] pb-6 flex flex-col justify-start items-center gap-2">
                            <div className="self-stretch h-[46px] flex flex-col justify-start items-center gap-2">
                                <div className="text-center text-sky-900 text-lg md:text-[42px] font-bold font-sans leading-[46.20px]">{t('reset_password')}</div>
                            </div>
                        </div>
                        <form onSubmit={handleReset} className="space-y-4">
                            <div className="self-stretch h-[76px] flex flex-col justify-start items-start gap-1">
                                <label htmlFor="email" className="self-stretch text-sky-900 text-sm font-normal leading-tight">{t('email')}</label>
                                <input
                                    type="email"
                                    autoComplete='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="email"
                                    className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300"
                                    placeholder={t('msg_enter_email')}
                                />
                            </div>
                            {errorMessage && (
                                <span className='text-red-600 font-bold'>{errorMessage}</span>
                            )}
                            <button
                                type="submit"
                                disabled={isResetting}
                                className={`w-full px-4 py-2 bg-sky-900 text-white font-medium rounded-lg ${isResetting ? 'cursor-not-allowed' : 'hover:bg-sky-900 hover:shadow-xl transition duration-300'}`}
                            >
                                {isResetting ? t('resetting_password') : t('reset_password')}
                            </button>
                            <div className="self-stretch h-[0px] border border-zinc-200"></div>
                            <div className="self-stretch text-center text-sky-900 text-sm font-normal leading-tight">
                                {t('remember_password')}{' '}
                                <button onClick={goBack} className="hover:underline font-bold">{t('go_back')}</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}
        </div>
    )
}

export default PasswordReset;

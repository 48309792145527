import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react';

import Table from '../home/Table';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Header from "../header";
import { useTranslation } from 'react-i18next';

import {
    doc,
    query,
    getDocs,
    setDoc,
    collection,
    where,
    Timestamp,
    and,
    or,
    getDoc,
} from 'firebase/firestore';
import db from '../../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from "../../contexts/authContext";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

function TableComponentUser() {
    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')
    if(currentUserRole !== 'user'){
        navigate('/accessDenied', { replace: true }); 
    }
    const { currentUser } = useAuth();
    const [selectedCloverIdBool, setSelectedCloverIdBool] = useState(false); // State to manage logout message

    const [selectedCloverId, setselectedCloverId] = useState(''); // State to manage selected MID
    const [logs, setLogs] = useState([]);
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(currentUser !== null);  // New state for logged in message
    const [remainingDays, setRemainingDays] = useState(7);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - today.getDay());
    const [startDate, setStartDate] = useState(formatDate(firstDayOfWeek)); // State for start date
    const [endDate, setEndDate] = useState(formatDate(today));
    const [users, setUsers] = useState();
    const [cloverIds, setCloverIds] = useState([]);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date());
    const [lastDayOfMonth, setLastDayOfMonth] = useState(new Date());
    const currentYear = new Date().getFullYear(); // Get the current year
    const [selectedYear, setSelectedYear] = useState(currentYear); // State to manage selected year
    const [isMobile, setIsMobile] = useState(false);
    const { cloverMID } = useParams();
    const [storeData, setStoreData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Location 1');
    const [searchTerm, setSearchTerm] = useState('');

    const restaurantsData = JSON.parse(localStorage.getItem("mevMandatarysData"))
    const restaurantIndex = restaurantsData.findIndex(item => item.cloverMerchantId === cloverMID);
    const selectedRestaurant = restaurantIndex !== -1 ? restaurantsData[restaurantIndex] : { businessName: '' };    
    const { t } = useTranslation();

    // const [storeData, setStoreData] = useState(null);

    const [data, setData] = useState([]);
    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getUsers = async () => {
        try {
            let isLoggedIn = false
            if (!localStorage.getItem('lookupData-' + selectedRestaurant.cloverMerchantId)) {
                // const querySnapshot = await getDocs(query(collection(db, "Lookup"), where("cloverId", "==", cloverMID )));
                // console.log("Users fetched from Firestore:", querySnapshot.docs.length);
                const querySnapshot = await getDocs(query(collection(db, "UserAccounts"), where('merchantId', '==', cloverMID)));
                console.log("UserAccounts fetched from Firestore:", querySnapshot.docs.length);

                const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                setUsers(usersData);
                localStorage.setItem('lookupData-' + selectedRestaurant.cloverMerchantId, JSON.stringify(usersData));
                
                const uniqueCloverIds = [...new Set(usersData.map(user => user.cloverId))];
                setCloverIds(uniqueCloverIds);

                const defaultStatusUsers = usersData.filter(user => user.status === "default");

                if (defaultStatusUsers.length > 0) {
                    defaultStatusUsers.forEach(user => {
                        const createdAtTimestamp = user.createdAt.toDate(); // Convert Firestore Timestamp to JavaScript Date object
                        const currentDate = new Date();
                        const diffTime = Math.abs(currentDate - createdAtTimestamp);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        const remainingDays = 7 - diffDays;
                        setRemainingDays(remainingDays);
                    });
                    isLoggedIn = true;
                }
            }
            setIsLoggedInMessage(!isLoggedIn);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        // const cachedUsersData = localStorage.getItem('lookupData-' + selectedRestaurant.cloverMerchantId);
        const cachedUsersData = localStorage.getItem('mevMandatarysData');

        const usersData = JSON.parse(cachedUsersData);
        // Array to store all cloverIds
        const cloverIds = [];

        // Iterate over each object in the array and extract cloverId
        // const cachedLogsData = localStorage.getItem('logsData-' + selectedRestaurant.cloverMerchantId);
        if (cachedUsersData ) {
            usersData.forEach(user => {
                cloverIds.push(user.cloverId);
            });
            let isLoggedIn = false

            setCloverIds(cloverIds)
            setUsers(JSON.parse(cachedUsersData));
            // setLogs(JSON.parse(cachedLogsData));
            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {
                    const createdAtTimestamp = user.lastModifiedDate; // Convert Firestore Timestamp to JavaScript Date object
                    const date = new Date(createdAtTimestamp.seconds * 1000 + createdAtTimestamp.nanoseconds / 1000000);
                    const currentDate = new Date();
                    const diffTime = Math.abs(currentDate - date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    const remainingDays = 7 - diffDays;
                    setRemainingDays(remainingDays);
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);

        } else {
            getUsers();
        }
    }, []);

    useEffect(() => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setFirstDayOfMonth(firstDay);
        setLastDayOfMonth(lastDay);
    }, []);

    useEffect(() => {
        if ( selectedRestaurant.businessName !== "") {
            // document.title = `${selectedRestaurant.businessName}`;
            localStorage.setItem('lastValidPath', window.location.pathname);
        }else{
            navigate('/error404')
        }
    }, [selectedRestaurant, navigate]);

    useEffect(() => {
        // Function to fetch BusinessNames from MEVMandatorys collection
        const fetchBusinessNames = async () => {
            try {
                const mevMandatarysData = localStorage.getItem('mevMandatarysData');
                if (!mevMandatarysData) {
                    console.error('No MEVMandatarys data found in localStorage');
                    return;
                }

                const parsedData = JSON.parse(mevMandatarysData);

                const result = parsedData.filter(item => item.businessName === selectedRestaurant.businessName);

                if (result.length > 0) {
                    const formattedResult = formatDates(result[0]);
                    setData(formattedResult);
                } else {
                    console.error('No matching business name found');
                }
            } catch (error) {
                console.error('Error fetching business names:', error);
            }
        };

        fetchBusinessNames();

        // Clean-up function to unsubscribe when component unmounts
        return () => { };
    }, [selectedRestaurant.businessName]);

    const formatDates = (data) => {
        const formattedData = { ...data };
        if (data.createdDate) {
            formattedData.createdDate = new Date(data.createdDate.seconds * 1000).toLocaleDateString();
        }
        if (data.lastModifiedDate) {
            formattedData.lastModifiedDate = new Date(data.lastModifiedDate.seconds * 1000).toLocaleDateString();
        }
        return formattedData;
    };

    // Your table JSX
    return (
        <Fragment>
            {isMobile && (
                <div className="w-full bg-stone-50 justify-start items-start">
                <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={false} />
            
                <div className="w-full p-4 flex flex-col justify-start items-start">
                    <div className="w-full relative">
                        <div className="flex flex-col justify-start items-center gap-2">
                            <div className="text-sky-900 text-lg font-bold">{selectedRestaurant.businessName}</div>
                        </div>
                    </div>
            
                    <div className="w-full p-6 bg-white shadow-lg rounded-lg">
            
                        <h1 className="text-3xl font-bold mb-6 text-center">{t('restaurant_information')}</h1>
            
                        <ul className="divide-y divide-gray-200">
                            <li className="py-4">
                                <span className="font-semibold">{t('activity_sub_sector')}:</span> {data.activitySubSector}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('authorization_code')}:</span> {data.authorizationCode}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('billing_file_number')}:</span> {data.billingFileNo}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('business_name')}:</span> {data.businessName}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('city')}:</span> {data.city}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('clover_merchant_id')}:</span> {data.cloverMerchantId}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('created_date')}:</span> {data.createdDate}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('identification_number')}:</span> {data.identificationNo}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('last_modified_date')}:</span> {data.lastModifiedDate}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('phone_number')}:</span> {data.phone}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('postal_code')}:</span> {data.postalCode}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('province')}:</span> {data.province}
                            </li>
                            <li className="py-4">
                            <span className="font-semibold">{t('status')}: </span> 
                                <span  className={`font-semibold ${
                                    data.status === true || data.status === "true" ? 'text-green-500' :
                                    data.status === false || data.status === "false" ? 'text-red-500' :
                                    'text-yellow-500'
                                }`} >
                                    {data.status === true || data.status === "true"
                                    ? t('active')
                                    : data.status === "default" 
                                    ? t('default')
                                    : t('not_active')}
                                </span>                            
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('street')} 1:</span> {data.street1}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('street')} 2:</span> {data.street2}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('tps_number')}:</span> {data.tpsNo}
                            </li>
                            <li className="py-4">
                                <span className="font-semibold">{t('tvq_number')}:</span> {data.tvqNo}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            )}
            {/* Content for desktop view */}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex  overflow-x-auto ">
                        <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={false} />

                        <div className="w-full h-[794px] p-6 flex-col justify-start items-start gap-16 inline-flex " style={{ paddingLeft: '17.5rem' }}>
                            <div className="w-full h-12 relative">
                                <div className="h-[46px] left-0 top-0 absolute flex-col justify-start items-start gap-12 inline-flex">
                                    <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                        <div className="self-stretch text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{selectedRestaurant.businessName}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full p-6 bg-white shadow-lg rounded-lg">

                                <h1 className="text-3xl font-bold mb-6 text-center">{t('restaurant_information')}</h1>
                                <ul className="divide-y divide-gray-200">
                                    <li className="py-4">
                                        <span className="font-semibold">{t('activity_sub_sector')}:</span> {selectedRestaurant.activitySubSector}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('authorization_code')}:</span> {selectedRestaurant.authorizationCode}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('billing_file_number')}:</span> {selectedRestaurant.billingFileNo}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('business_name')}:</span> {selectedRestaurant.businessName}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('city')}:</span> {selectedRestaurant.city}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('clover_merchant_id')}:</span> {selectedRestaurant.cloverMerchantId}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('created_date')}:</span> {new Date(selectedRestaurant.createdDate.seconds * 1000).toLocaleDateString()}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('identification_number')}:</span> {selectedRestaurant.identificationNo}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('last_modified_date')}:</span> {new Date(selectedRestaurant.lastModifiedDate.seconds * 1000).toLocaleDateString()}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('phone_number')}:</span> {selectedRestaurant.phone}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('postal_code')}:</span> {selectedRestaurant.postalCode}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('province')}:</span> {selectedRestaurant.province}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('status')}: </span> 
                                        <span  className={`font-semibold ${
                                            selectedRestaurant.status === true || selectedRestaurant.status === "true" ? 'text-green-500' :
                                            selectedRestaurant.status === false || selectedRestaurant.status === "false" ? 'text-red-500' :
                                            'text-yellow-500'
                                        }`} >
                                            {selectedRestaurant.status === true || selectedRestaurant.status === "true"
                                            ? t('active')
                                            : selectedRestaurant.status === "default" 
                                            ? t('default')
                                            : t('not_active')}
                                        </span>
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('street')} 1:</span> {selectedRestaurant.street1}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('street')} 2:</span> {selectedRestaurant.street2}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('tps_number')}:</span> {selectedRestaurant.tpsNo}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('tvq_number')}:</span> {selectedRestaurant.tvqNo}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default TableComponentUser;

import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react';
import Table from '../../home/Table';
import Header from "../../header";
import { RefreshIcon } from '@heroicons/react/outline';

import {
    query,
    getDocs,
    collection,
    where,
    orderBy
} from 'firebase/firestore';
import db from '../../../firebase/firebase';
import { useAuth } from "../../../contexts/authContext";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EventUser() {
    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')
    if (currentUserRole !== 'user') {
        navigate('/accessDenied', { replace: true });
    }
    const { currentUser } = useAuth();
    const [logs, setLogs] = useState([]);
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(currentUser !== null);  // New state for logged in message
    const [remainingDays, setRemainingDays] = useState(7);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - today.getDay());
    const [startDate, setStartDate] = useState(formatDate(today)); // State for start date
    const [endDate, setEndDate] = useState(formatDate(today));
    const [users, setUsers] = useState();
    const [cloverIds, setCloverIds] = useState([]);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date());
    const [lastDayOfMonth, setLastDayOfMonth] = useState(new Date());
    const [isMobile, setIsMobile] = useState(false);
    const { cloverMID } = useParams();
    const [data, setData] = useState([]);
    const restaurantsData = JSON.parse(localStorage.getItem("mevMandatarysData"))
    const restaurantIndex = restaurantsData.findIndex(item => item.cloverMerchantId === cloverMID);
    const selectedRestaurant = restaurantIndex !== -1 ? restaurantsData[restaurantIndex] : { businessName: '' };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // You can adjust this value as needed
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [searchTerm, setSearchTerm] = useState('');
    const [employees, setEmployees] = useState([]);
    const [devices, setDevices] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [startTime, setStartTime] = useState('00:00'); // 12:00AM
    const [endTime, setEndTime] = useState('23:59'); 
    const [isDisabled, setIsDisabled] = useState(false);

    function formatType(type){
        let formattedType = type;

        if (formattedType === "ORDER" ){
            formattedType = t('order')
        }else if(formattedType === "WEB_SRM"){
            formattedType = t('web_srm')
        }else if(formattedType === "CONNECTION"){
            formattedType = t('connection')
        }
        return formattedType;
    }
    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const { t } = useTranslation();

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if ( selectedRestaurant.businessName !== "") {
            // document.title = `${selectedRestaurant.businessName}`;
            localStorage.setItem('lastValidPath', window.location.pathname);
        }else{
            navigate('/error404')
        }
    }, [selectedRestaurant, navigate]);

    const getUsers = async () => {
        try {
             // Disable the button when clicked
             setIsDisabled(true);

             // Set a timer to re-enable the button after 15 seconds
             setTimeout(() => {
             setIsDisabled(false);
             }, 15000); // 15000 milliseconds = 15 seconds
            let isLoggedIn = false
            // const cachedUsersData = localStorage.getItem('lookupData-' + selectedRestaurant.cloverMerchantId);
            const cachedUsersData = localStorage.getItem('mevMandatarysData' );

            const usersData = JSON.parse(cachedUsersData);

            setUsers(usersData);
            // Fetch logs linked to users
            // if (!localStorage.getItem('logsData-' + cloverMID)) {
            if(!isDisabled){
                const logsQuery = query(
                    collection(db, "Logs"),
                    where('cloverMerchantId', '==', cloverMID),
                    orderBy('timeStamp', 'desc')          
                );
                try {
                    // Fetch the documents matching the query
                    const logsQuerySnapshot = await getDocs(logsQuery);
                    console.log("Logs fetched from Firestore:", logsQuerySnapshot.docs.length);
                    const logsData = logsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setLogs(logsData);
                    localStorage.setItem('logsData-' + cloverMID, JSON.stringify(logsData));
                } catch (error) {
                    console.error("Error fetching logs:", error);
                }
            }
            const uniqueCloverIds = [...new Set(usersData.map(user => user.cloverId))];
            setCloverIds(uniqueCloverIds);

            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {
                    const createdAtTimestamp = user.lastModifiedDate; // Convert Firestore Timestamp to JavaScript Date object
                    const date = new Date(createdAtTimestamp.seconds * 1000 + createdAtTimestamp.nanoseconds / 1000000);
                    const currentDate = new Date();
                    const diffTime = Math.abs(currentDate - date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    const remainingDays = 7 - diffDays;
                    setRemainingDays(remainingDays);
                });
                isLoggedIn = true;
            }
            // }
            setIsLoggedInMessage(!isLoggedIn);
            
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        // const cachedUsersData = localStorage.getItem('lookupData-' + cloverMID);
        const cachedUsersData = localStorage.getItem('mevMandatarysData' );

        const usersData = JSON.parse(cachedUsersData);

        // Array to store all cloverIds
        const cloverIds = [];

        // Iterate over each object in the array and extract cloverId
        const cachedLogsData = localStorage.getItem('logsData-' + cloverMID);
        if (cachedUsersData && cachedLogsData) {
            getUsers()
            usersData.forEach(user => {
                cloverIds.push(user.cloverId);
            });
            let isLoggedIn = false

            setCloverIds(cloverIds)
            setUsers(JSON.parse(cachedUsersData));
            setLogs(JSON.parse(cachedLogsData));
            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {
                    const createdAtTimestamp = user.lastModifiedDate; // Convert Firestore Timestamp to JavaScript Date object
                    const date = new Date(createdAtTimestamp.seconds * 1000 + createdAtTimestamp.nanoseconds / 1000000);
                    const currentDate = new Date();
                    const diffTime = Math.abs(currentDate - date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    const remainingDays = 7 - diffDays;
                    setRemainingDays(remainingDays);
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);

        } else {
            getUsers();
        }
    }, []);

    useEffect(() => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setFirstDayOfMonth(firstDay);
        setLastDayOfMonth(lastDay);
    }, []);

    const filteredByDate = logs.filter(user => {
        // if (user.orderId !== null) {
            const timestamp = parseInt(user.timeStamp);
            const date = new Date(timestamp);
        
            // Get the current time
            let now = new Date();
            let currentHours = now.getUTCHours() + 4;
            let currentMinutes = now.getUTCMinutes();
            let currentSeconds = now.getUTCSeconds();
    
            // Ensure endDate is properly formatted (assumed format: YYYY-MM-DD)
            let endDateWithCurrentTime = new Date(Date.UTC(
                parseInt(endDate.substring(0, 4)),   // Year
                parseInt(endDate.substring(5, 7)) - 1, // Month (zero-indexed)
                parseInt(endDate.substring(8, 10)),  // Day
                currentHours,                        // Hours
                currentMinutes,                      // Minutes
                currentSeconds                       // Seconds
            ));
            let [endHours, endMinutes] = endTime.split(":").map(Number);
            endDateWithCurrentTime.setHours(endHours);
            endDateWithCurrentTime.setMinutes(endMinutes);
    
            if (isNaN(endDateWithCurrentTime.getTime())) {
                console.error('Invalid end date format.');
                return false;  // Skip this transaction
            }
    
            // Create a new Date object with the startDate and adjusted time
            let startDateWithCurrentTime = new Date(`${startDate}T${startTime}`); // This will keep the date as 2024-08-19 in your local time zone.        
            let [startHours, startMinutes] = startTime.split(":").map(Number);
            startDateWithCurrentTime.setHours(startHours);
            startDateWithCurrentTime.setMinutes(startMinutes);
            // startDateWithCurrentTime.setUTCHours(startDateWithCurrentTime.getUTCHours() + 4);
    
            const dateGreaterThenRange = date > startDateWithCurrentTime;
            const dateLowerThenRange = date < endDateWithCurrentTime;
            const dateInRange = dateGreaterThenRange && dateLowerThenRange;
        
            const orderIdMatches = searchTerm === '' || user.orderId.toUpperCase().includes(searchTerm.toUpperCase());
            const deviceMatches = selectedDevice === '' || user.deviceId === selectedDevice;
            const employeeMatches = selectedEmployee === '' || user.employee.cloverId === selectedEmployee;
            const typeMatches = selectedType === '' || formatType(user.type) === selectedType;
            const timeInRange = startTime && endTime;

            return dateInRange && orderIdMatches && deviceMatches && employeeMatches && typeMatches && timeInRange;
        // }
    });

    const totalPages = Math.ceil(filteredByDate.length / itemsPerPage);
    const filteredDevices = devices.filter(device => {
        return device
    });
    // Function to handle StartDate entry
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    // Function to handle EndDate entry
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleStartTimeChange = (event) => {
        setStartTime(event.target.value);
    };

    const handleEndTimeChange = (event) => {
        setEndTime(event.target.value);
    };

    const handleDropdown = async () => {
        const deviceQuery = query(
            collection(db, "Certificates"),
            where("cloverMerchantId", '==', cloverMID),
            // orderBy('datTrans', 'desc')
        );

        const employeeQuery = query(
            collection(db, "UserAccounts"),
            where("merchantId", '==', cloverMID),
            // orderBy('datTrans', 'desc')
        );

        const devicesQuerySnapshot = await getDocs(deviceQuery);
        console.log("Devices fetched from Firestore:", devicesQuerySnapshot.docs.length);
        const devicesData = devicesQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const removeDuplicatesDevices = (list) => {
            const uniqueDevices = new Map();
            list.forEach(device => {
                if (!uniqueDevices.has(device.cloverDeviceId)) {
                    uniqueDevices.set(device.cloverDeviceId, device);
                }
            });
            return Array.from(uniqueDevices.values());
        };

        const uniqueDeviceList = removeDuplicatesDevices(devicesData);

        setDevices(uniqueDeviceList);

        const employeesQuerySnapshot = await getDocs(employeeQuery);
        console.log("Employees fetched from Firestore:", employeesQuerySnapshot.docs.length);
        const employeesData = employeesQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const removeDuplicates = (list) => {
            const uniqueEmployees = new Map();
            list.forEach(employee => {
                if (!uniqueEmployees.has(employee.employeeId) && employee.deactivated !== true ) {
                    uniqueEmployees.set(employee.employeeId, employee);
                }
            });
            return Array.from(uniqueEmployees.values());
        };

        const uniqueEmployeeList = removeDuplicates(employeesData);
        setEmployees(uniqueEmployeeList);
    };

    useEffect(() => {
        handleDropdown();
    }, []);

    const handleChangeDevice = (e) => {
        setSelectedDevice(e.target.value);
    };
    const handleChangeEmployee = (e) => {
        setSelectedEmployee(e.target.value);
    };
    const handleChangeType = (e) => {
        setSelectedType(e.target.value);
    };

    useEffect(() => {
        // Function to fetch BusinessNames from MEVMandatorys collection
        const fetchBusinessNames = async () => {
            try {
                const mevMandatarysData = localStorage.getItem('mevMandatarysData');
                if (!mevMandatarysData) {
                    console.error('No MEVMandatarys data found in localStorage');
                    return;
                }
                const parsedData = JSON.parse(mevMandatarysData);
                const result = parsedData.filter(item => item.businessName === selectedRestaurant.businessName);

                if (result.length > 0) {
                    const formattedResult = formatDates(result[0]);
                    setData(formattedResult);
                } else {
                    console.error('No matching business name found');
                }
            } catch (error) {
                console.error('Error fetching business names:', error);
            }
        };
        fetchBusinessNames();

        // Clean-up function to unsubscribe when component unmounts
        return () => { };
    }, [selectedRestaurant.businessName]);

    const formatDates = (data) => {
        const formattedData = { ...data };
        if (data.createdDate) {
            formattedData.createdDate = new Date(data.createdDate.seconds * 1000).toLocaleDateString();
        }
        if (data.lastModifiedDate) {
            formattedData.lastModifiedDate = new Date(data.lastModifiedDate.seconds * 1000).toLocaleDateString();
        }
        return formattedData;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const downloadCSV = () => {
        function getDeviceName(cloverDeviceId) {
            const device = filteredDevices.find(device => device.cloverDeviceId === cloverDeviceId);
            return device ? device.deviceName : 'Unknown';
        }
        function formatType(type){
            let formattedType = type;
    
            if (formattedType === "ORDER" ){
                formattedType = t('order')
            }else if(formattedType === "WEB_SRM"){
                formattedType = t('web_srm')
            }else if(formattedType === "CONNECTION"){
                formattedType = t('connection')
            }
            return formattedType;
        }

        let csvContent = '\ufeff' +
            t('date') + " / " + t('time') + "," + 
            t('employees') + "," + 
            t('events') + "," + 
            t('devices') + "," + 
            t('clover_device_id') + "," + 
            t('bill_type') + "\n"; 

        filteredByDate.forEach((user, index) => {

            const timestampNumber = parseInt(user.timeStamp, 10);
            const eventDate = new Date(timestampNumber);
            const formattedEventDate = `${eventDate.getFullYear()}-${(eventDate.getMonth() + 1).toString().padStart(2, '0')}-${eventDate.getDate().toString().padStart(2, '0')} ${eventDate.getHours().toString().padStart(2, '0')}:${eventDate.getMinutes().toString().padStart(2, '0')}:${eventDate.getSeconds().toString().padStart(2, '0')}`;
            const deviceName = getDeviceName(user.deviceId);
            const formattedType = formatType(user.type)
            let parsedEvent;
            // Attempt to parse the event as JSON
            try {
                parsedEvent = JSON.parse(user.event);

            } catch (e) {
                parsedEvent = null;
            }
            const eventMessage = parsedEvent ? parsedEvent.mess : user.event;
            const eventId = parsedEvent ? parsedEvent.id : ''; // Use a default value or empty string if no ID
            
            // Note: To ensure proper handling of newlines in CSV cells, it's a good practice to wrap cell content in double quotes
            csvContent += `${formattedEventDate},${user.employee.name},"${eventMessage}\n${eventId}",${deviceName},${user.deviceId},${formattedType}\n`;
            
            if (index < user.length - 1 && user.orderId !== user[index + 1].orderId) {
                csvContent += '\n';
            }
        });

        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "CloverMevWebEventReport.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // const uniqueTypes = Array.from(new Set(filteredByDate.map(filter => filter.type)));
   
    // Your table JSX
    return (
        <Fragment>
            {isMobile && (
                <div className="w-full bg-stone-50 justify-start items-start overflow-x-auto">
                    <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={false} />

                    <div className="w-full p-4 flex flex-col justify-start items-start">
                        <div className="w-full relative">
                            <div className="flex flex-col justify-start items-center gap-2">
                                <div className="text-sky-900 text-lg font-bold">{selectedRestaurant.businessName}</div>
                            </div>
                        </div>
                        <div className="w-full py-4 flex justify-center">
                            <button 
                            className="w-[172px] h-16 px-3 py-4 bg-white rounded-[5px] ml-4 hover:bg-sky-900 active:scale-95 active:bg-sky-800 transition transform duration-150 ease-in-out border-2 border-sky-900 justify-center items-center flex text-sky-900 text-base hover:text-white font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide" 
                            onClick={getUsers} disabled={isDisabled}> 
                                    <RefreshIcon className="w-6 h-6 relative mr-2"/>
                                    {isDisabled ?  t('wait') : t('reload')}         
                            </button>
                        </div>

                        <div className="w-full border-b border-gray-400 my-4"></div>

                        <div className="w-full h-12 justify-start items-center inline-flex">
                            {/* <div className="grow shrink basis-0 h-12 px-4 py-3 bg-white border-b border-neutral-300 justify-start items-center gap-2 flex w-1/2 mx-auto">                                
                            <div className="w-6 h-6 relative searchIcon" />
                                <div className="w-6 h-6 relative searchIcon" />
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder={t('search_by_order_id')}
                                    className="grow shrink basis-0 text-sky-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                                />
                            </div> */}
                            <div className="w-full py-4 justify-center items-center gap-2 flex">
                                <div className="justify-center items-center gap-2 flex">
                                    <button className="w-[172px] h-12 px-3 py-4 bg-white rounded-[5px] ml-4 hover:bg-sky-900   border-2 border-sky-900 justify-center items-center flex text-sky-900 text-base hover:text-white font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide" onClick={downloadCSV}>
                                        {t('download_csv')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-between items-center my-4">
                            <div className="w-full justify-start items-center inline-flex">
                                <div className="grow shrink basis-0 px-4 py-2 bg-white border-b border-neutral-300 justify-start items-start gap-2 flex flex-col">
                                    <input
                                        id="startDate"
                                        type="date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        className="text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                                    />
                                    <input
                                        id="startTime"
                                        type="time"
                                        value={startTime}
                                        onChange={handleStartTimeChange}
                                        min="00:00" // Optional: Set minimum time
                                        max="23:59" // Optional: Set maximum time
                                        className="text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                                    />
                                </div>

                                <div className="w-12 self-stretch bg-zinc-200 border-b border-neutral-300 justify-center items-center gap-2.5 flex ">
                                    <div className="w-6 h-6 relative logoArrowRight" />
                                </div>
                                <div className="grow shrink basis-0 px-4 py-2 bg-white border-b border-neutral-300 justify-start items-start gap-2 flex flex-col">
                                    <input
                                        id="endDate"
                                        type="date"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        className="text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                                    />
                                    <input
                                        id="endTime"
                                        type="time"
                                        value={endTime}
                                        onChange={handleEndTimeChange}
                                        min="00:00" // Optional: Set minimum time
                                        max="23:59" // Optional: Set maximum time
                                        className="text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full bg-white justify-start items-center gap-2 flex cursor-pointer">
                            <select
                                id="dropdown"
                                value={employees.employeeId}
                                onChange={handleChangeEmployee}
                                className="w-full h-10 px-2 text-zinc-500 bg-white border border-neutral-300 rounded cursor-pointer focus:outline-none"
                            >
                                <option value="">{t('all_employees')}</option>
                                {employees.map(employee => (
                                    <option key={employee.id} value={employee.employeeId}>{employee.name}</option>
                                ))}
                            </select>
                            <select
                                id="dropdown"
                                value={devices.deviceName}
                                onChange={handleChangeDevice}
                                className="w-full h-10 px-2 text-zinc-500 bg-white border border-neutral-300 rounded cursor-pointer focus:outline-none"
                            >
                                <option value="">{t('all_devices')}</option>
                                {devices.map(device => (
                                    <option key={device.id} value={device.cloverDeviceId}>{device.deviceName}</option>
                                ))}
                            </select>
                            <select
                                id="dropdown"
                                value={formatType(filteredByDate.type)}
                                onChange={handleChangeType}
                                className="w-full h-10 px-2 text-zinc-500 bg-white border border-neutral-300 rounded cursor-pointer focus:outline-none"
                            >
                                <option value="">{t('all_types')}</option>
                                <option value={t('connection')}>{t('connection')}</option>
                                <option value={t('order')}>{t('order')}</option>
                                <option value={t('web_srm')}>{t('web_srm')}</option>
                            </select>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <Table events={filteredByDate.slice(indexOfFirstItem, indexOfLastItem)} deviceData={filteredDevices}/>
                        </div>

                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            )}
            {/* Content for desktop view */}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex  overflow-x-auto ">

                        <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={false} />

                        <div className="w-full h-full p-6 flex-col justify-start items-start gap-16 inline-flex " style={{ paddingLeft: '17.5rem' }}>
                        <div className="w-full h-12 flex justify-between items-center">
                                <div className="w-full h-12 relative">
                                    <div className="h-[46px] left-0 top-0 absolute flex-col justify-start items-start gap-12 inline-flex">
                                        <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                            <div className="self-stretch text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{selectedRestaurant.businessName}</div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="w-[200px] py-4 flex justify-end">
                                    <button 
                                    className="w-[172px] h-16 px-3 py-4 bg-white rounded-[5px] ml-4 hover:bg-sky-900 active:scale-95 active:bg-sky-800 transition transform duration-150 ease-in-out border-2 border-sky-900 justify-center items-center flex text-sky-900 text-base hover:text-white font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide" 
                                    onClick={getUsers} disabled={isDisabled}> 
                                            <RefreshIcon className="w-6 h-6 relative mr-2"/>
                                            {isDisabled ?  t('wait') : t('reload')}         
                                    </button>
                                </div>
                            </div>
                            
                            <div className="w-full border-b justify-between items-center inline-flex ">
                                <div className="justify-start items-center gap-11 flex ">
                                    <div className="justify-end items-center flex">
                                    <div className="w-[178px] px-4 py-2 bg-white border-b border-neutral-300 justify-start items-center gap-2 flex flex-col">
                                            
                                            <input
                                                id="startDate"
                                                type="date"
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                // min={formatDate(firstDayOfMonth)} // Set min attribute to the first day of the current month
                                                // max={formatDate(lastDayOfMonth)}
                                                className="w-full h-6"
                                            />
                                            <input
                                                id="startTime"
                                                type="time"
                                                value={startTime}
                                                onChange={handleStartTimeChange}
                                                min="00:00" // Optional: Set minimum time
                                                max="23:59" // Optional: Set maximum time
                                                className="w-full h-6"
                                            />
                                        </div>

                                        <div className="w-12 self-stretch bg-zinc-200 border-b border-neutral-300 justify-center items-center gap-2.5 flex">
                                            <div className="w-6 h-6 relative logoArrowRight" />
                                        </div>

                                        <div className="w-[178px] px-4 py-2 bg-white border-b border-neutral-300 justify-start items-center gap-2 flex flex-col">
                                            <input
                                                id="endDate"
                                                type="date"
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                min={startDate} // Set min attribute to the first day of the current month
                                                max={formatDate(lastDayOfMonth)}
                                                className="w-full h-6 relative"
                                            />
                                            <input
                                                id="endTime"
                                                type="time"
                                                value={endTime}
                                                onChange={handleEndTimeChange}
                                                min="00:00" // Optional: Set minimum time
                                                max="23:59" // Optional: Set maximum time
                                                className="w-full h-6 relative"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-end items-center flex">
                                    <div className="w-[200px] py-4 justify-center items-center gap-2 flex">
                                        <div className="justify-center items-center gap-2 flex">
                                            <button className="w-[172px] h-12 px-3 py-4 bg-white rounded-[5px] ml-4 hover:bg-sky-900   border-2 border-sky-900 justify-center items-center flex text-sky-900 text-base hover:text-white font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide" onClick={downloadCSV}>
                                                {t('download_csv')}
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="w-[178px] px-4 py-3 bg-white border-b border-neutral-300 justify-start items-center gap-2 flex">
                                        <div className="w-6 h-6 relative searchIcon" />
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            placeholder={t('search_by_order_id')}
                                            className="w-[114px] text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className="w-full bg-white justify-start items-center gap-2 flex cursor-pointer">
                                <select
                                    id="dropdown"
                                    value={employees.employeeId}
                                    onChange={handleChangeEmployee}
                                    className="w-full h-10 px-2 text-zinc-500 bg-white border border-neutral-300 rounded cursor-pointer focus:outline-none"
                                >
                                    <option value="">{t('all_employees')}</option>
                                    {employees.map(employee => (
                                        <option key={employee.id} value={employee.employeeId}>{employee.name}</option>
                                    ))}
                                </select>
                                <select
                                    id="dropdown"
                                    value={devices.deviceName}
                                    onChange={handleChangeDevice}
                                    className="w-full h-10 px-2 text-zinc-500 bg-white border border-neutral-300 rounded cursor-pointer focus:outline-none"
                                >
                                    <option value="">{t('all_devices')}</option>
                                    {devices.map(device => (
                                        <option key={device.id} value={device.cloverDeviceId}>{device.deviceName}</option>
                                    ))}
                                </select>
                                <select
                                    id="dropdown"
                                    value={formatType(filteredByDate.type)}
                                    onChange={handleChangeType}
                                    className="w-full h-10 px-2 text-zinc-500 bg-white border border-neutral-300 rounded cursor-pointer focus:outline-none"
                                >
                                    <option value="">{t('all_types')}</option>
                                    <option value={t('connection')}>{t('connection')}</option>
                                    <option value={t('order')}>{t('order')}</option>
                                    <option value={t('web_srm')}>{t('web_srm')}</option>
                                </select>
                            </div>
                            <div className=" w-full overflow-x-auto">
                                <Table events={filteredByDate.slice(indexOfFirstItem, indexOfLastItem)} deviceData={filteredDevices} />
                            </div>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange} />
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

function Pagination({ totalPages, currentPage, onPageChange }) {
    if (totalPages === 0) {
        return null;
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    let displayedPages = pageNumbers;

    if (totalPages > 3) {
        if (currentPage <= 2) {
            displayedPages = [...pageNumbers.slice(0, 3), "...", totalPages];
        } else if (currentPage >= totalPages - 1) {
            displayedPages = [1, "...", ...pageNumbers.slice(totalPages - 3, totalPages)];
        } else {
            displayedPages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
        }
    }

    return (
        <div className="self-stretch justify-center items-center inline-flex">
            <div className="w-[122px] p-2 justify-center items-center flex">
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-2 justify-center items-center gap-2.5 flex"
                >
                    <div className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">Previous</div>
                </button>
            </div>
            {displayedPages.map((number, index) => (
                <div key={index} className={`w-[42px] p-2 ${number === currentPage ? 'bg-white' : ''} justify-center items-center flex`}>
                    <button
                        onClick={() => onPageChange(number)}
                        className="px-2 justify-center items-center flex"
                    >
                        <div className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{number === "..." ? "..." : number}</div>
                    </button>
                </div>
            ))}
            <div className="w-[91px] p-2 justify-center items-center flex">
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-2 justify-center items-center gap-2.5 flex"
                >
                    <div className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">Next</div>
                </button>
                <div className="w-6 h-6 relative" />
            </div>
        </div>
    );
}

export default EventUser;

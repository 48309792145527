import React, { useEffect, useState } from "react";
import { useLocation, useNavigate , NavLink} from "react-router-dom";
import { verifyEmail } from "../../../firebase/auth"; // Adjust the import based on your project structure

var parameters = undefined
const AuthAction = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    parameters = new URLSearchParams(useLocation().search);

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.get("mode") === 'resetPassword'){
                window.location.href = `${window.location.origin}/customReset?oobCode=${parameters.get('oobCode')}`
            }
            else if (parameters.get("mode") === 'verifyEmail'){
                window.location.href = `${window.location.origin}/emailVerified?oobCode=${parameters.get('oobCode')}`
            }
        };
    
        fetchData();
    }, []);
};
export default AuthAction;
import React from "react"
import { useNavigate, NavLink, useLocation, useParams } from 'react-router-dom'
import { useAuth } from "../../contexts/authContext"
import { doSignOut } from "../../firebase/auth"
import { useLayoutEffect, useState, useEffect } from 'react';
import { DeviceTabletIcon, CreditCardIcon, ClipboardListIcon, HomeIcon, UsersIcon } from '@heroicons/react/outline';
import LanguageSwitcher from "../languageSwitcher";
import { useTranslation } from 'react-i18next';

const Header = ({ variableFromPageA, remainingDays, isAdmin }) => {

    const navigate = useNavigate()
    const { userLoggedIn } = useAuth()
    const { currentUser } = useAuth();

    const [isMobile, setIsMobile] = useState(false);
    const [showUsers, setShowUsers] = useState(true);
    const [showGenerateR, setShowGenerateR] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [remainingDaysCount, setRemainingDaysCount] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;
    const [firstname, setFirstname] = useState('');
    const { cloverMID } = useParams();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!variableFromPageA) {

            if (remainingDays < 8) {
                setNotificationCount(notificationCount + 1);
                setRemainingDaysCount(remainingDaysCount + remainingDays)
            }
        }
    }, [variableFromPageA, remainingDays, showUsers, showGenerateR]);

    const notificationMessages = [];

    if (remainingDaysCount > 0 || remainingDaysCount < 0) {
        notificationMessages.push(t('you_have') + remainingDaysCount + t('remaining_days'));
    } else {
        notificationMessages.push(t('zero_notif'));
    }

    const handleNotificationClick = () => {
        setNotificationCount(0); // Reset notification count when clicked
        setShowDropdown(!showDropdown); // Toggle dropdown visibility

    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownVisible && !event.target.closest('.relative')) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownVisible]);

    const UserThumbnail = ({ status }) => (
        <div className="w-12 h-12 bg-slate-100 rounded-full flex items-center justify-center relative logoUserThumb">
            {status && (
                <div
                    className={`absolute bottom-0 right-0 w-4 h-4 rounded-full ${status === 'online' ? 'bg-green-500' : 'bg-yellow-500'
                        }`}
                ></div>
            )}
        </div>
    );

    const UserDropdown = ({ onLogout }) => (
        isMobile ? (
            <>
                {/* Mobile-specific content (if any) */}
                <div className="w-full bg-white border border-gray-200 rounded shadow-lg flex flex-col mt-2 ">
                    <NavLink to="/reset" className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                        <div className="w-6 h-6 logoReset"></div>
                        <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{t('reset_password')}</span>
                    </NavLink>
                    <LanguageSwitcher />
                    <NavLink onClick={onLogout} className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                        <div className="w-6 h-6 logoLogout"></div>
                        <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{t('logout')}</span>
                    </NavLink>
                </div>
            </>
        ) : (
            <>
                <div className="w-full bg-white border border-gray-200 rounded shadow-lg flex flex-col mt-2">
                    <NavLink to="/reset" className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                        <div className="w-6 h-6 logoReset"></div>
                        <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{t('reset_password')}</span>
                    </NavLink>
                    <LanguageSwitcher />
                    <NavLink onClick={onLogout} className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                        <div className="w-6 h-6 logoLogout"></div>
                        <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{t('logout')}</span>
                    </NavLink>
                </div>
            </>
        )
    );


    useEffect(() => {
        const displayName = currentUser && currentUser.displayName;
        setFirstname(displayName);
    }, []);

    return (

        <div className={isMobile ? "w-full" : "flex flex-col"}>
            <nav className={isMobile ? "w-full h-20 p-4 bg-white border-b border-zinc-200 justify-between items-center inline-flex " : "flex flex-col w-60 z-20 fixed top-0 left-0 h-full bg-white border-l border-r border-zinc-200 flex-col justify-center items-start gap-4 inline-flex "}>
                {/* Content for mobile view */}
                {isMobile && (
                    <>
                        {isAdmin ? (
                            // Mobile Is Admin Header
                            <>
                                <div className="w-[393px] h-20 p-4 bg-white border-b border-zinc-200 justify-between items-center z-10">
                                    <div className="inline-flex">
                                        <div className="flex-col justify-center items-start gap-2.5 inline-flex logoNuage">
                                            <div className="w-[131.18px] h-[39px] relative"></div>
                                        </div>

                                        <div className="relative flex items-center w-full">
                                            <div className="relative flex items-center">
                                                <div onClick={toggleDropdown} className="relative">
                                                    <UserThumbnail status={'online'} />
                                                </div>
                                            </div>
                                            <div className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                                                <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{firstname}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible && (
                                        <div className="w-full">
                                            <UserDropdown
                                                onLogout={() => {
                                                    doSignOut().then(() => {
                                                        navigate('/login');
                                                        localStorage.clear();
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            // Desktop User Header Mobile
                            <>
                                <div className="w-[393px] h-20  bg-white border-b border-zinc-200 justify-between items-center z-10 ">
                                    <div className="inline-flex">
                                        <div className="flex-col justify-center items-start gap-2.5 inline-flex logoNuage">
                                            <div className="w-[131.18px] h-[39px] relative"></div>
                                        </div>

                                        <div className="relative flex items-center w-full">
                                            <div className="relative flex items-center">
                                                <div onClick={toggleDropdown} className="relative">
                                                    <UserThumbnail status={variableFromPageA ? 'online' : 'offline'} />
                                                </div>
                                                <div className="justify-end items-center flex">
                                                    <div className="relative w-12 px-2 py-3 justify-center items-center gap-4 flex">
                                                        <div className="px-2 py-1 bg-sky-500 rounded-xl justify-center items-center gap-2.5 flex logoBell" onClick={handleNotificationClick}></div>
                                                        {notificationCount >= 0 && (
                                                            <div className="absolute top-1.5 right-2 w-4 h-4 px-[5.50px] py-[0.50px] bg-sky-500 rounded-xl justify-center items-center gap-2.5 flex">
                                                                <div className="text-center text-white text-xs font-normal font-['Roboto'] leading-none">{notificationCount}</div>
                                                            </div>
                                                        )}
                                                        {showDropdown && (
                                                            <div className="absolute top-10 right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
                                                                <ul >
                                                                    {notificationMessages.map((message, index) => (
                                                                        <li key={index} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">{message}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                                                <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{firstname}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible && (
                                        <div className="w-full">
                                            <UserDropdown
                                                onLogout={() => {
                                                    doSignOut().then(() => {
                                                        navigate('/login');
                                                        localStorage.clear();
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}

                {/* Content for desktop view */}
                {!isMobile && (
                    <>
                        {isAdmin ? (
                            // Mobile Is Admin Header
                            <>
                                <div className="self-stretch h-[90px] flex-col justify-start items-center gap-2.5 flex logoNuage mt-4"
                                    onClick={() => navigate('/admin')}>
                                </div>
                                <div className="self-stretch justify-center items-center inline-flex flex-col">
                                    <div className="relative flex items-center w-full ml-4">
                                        <div className="relative flex items-center">
                                            <div onClick={toggleDropdown} className="relative">
                                                <UserThumbnail status={'online'} />
                                            </div>
                                        </div>
                                        <div className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                                            <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{firstname}</span>
                                        </div>
                                    </div>
                                    {dropdownVisible && (
                                        <div className="w-full">
                                            <UserDropdown
                                                onLogout={() => {
                                                    doSignOut().then(() => {
                                                        navigate('/login');
                                                        localStorage.clear();
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                    {showDropdown && (
                                        <div className="relative mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg flex flex-col">
                                            <ul>
                                                {notificationMessages.map((message, index) => (
                                                    <li key={index} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                        {message}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>

                                <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start flex">
                                    {userLoggedIn && (
                                        <>
                                            <NavLink to={'/admin'} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                <HomeIcon className="w-6 h-6 relative " />
                                                <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                    {t('restaurants')}
                                                </span>
                                            </NavLink>
                                            {currentPath.startsWith('/restaurant-list') && (
                                                <>
                                                    <NavLink to={`/restaurant-list/web-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        {/* <div className="w-6 h-6 relative logoAddUser"></div> */}
                                                        <UsersIcon className="w-6 h-6 relative " />

                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('web_user')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list/device-admin/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <DeviceTabletIcon className="w-6 h-6 relative " />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('devices')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list/transaction-admin/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <CreditCardIcon className="w-6 h-6 relative " />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('transactions')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list/event-admin/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <ClipboardListIcon className="w-6 h-6 relative" />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('events')}
                                                        </span>
                                                    </NavLink>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            // Desktop User Header
                            <>

                                <div className="self-stretch h-[90px] flex-col justify-start items-center gap-2.5 flex logoNuage  mt-4"
                                    onClick={() => navigate('/dashboard')}></div>
                                <div className="self-stretch justify-center items-center inline-flex flex-col">
                                    <div className="relative flex items-center w-full ml-4">
                                        <div className="relative flex items-center">
                                            <div onClick={toggleDropdown} className="relative">
                                                <UserThumbnail status={variableFromPageA ? 'online' : 'offline'} />
                                            </div>
                                            <div className="relative w-12 px-2 py-3 flex items-center gap-4">
                                                <div
                                                    className="px-2 py-1 bg-sky-500 rounded-xl flex items-center logoBell"
                                                    onClick={handleNotificationClick}
                                                ></div>
                                                {notificationCount >= 0 && (
                                                    <div className="absolute top-1.5 right-2 w-4 h-4 px-[5.50px] py-[0.50px] bg-sky-500 rounded-xl flex items-center">
                                                        <div className="text-center text-white text-xs font-normal font-['Roboto'] leading-none">
                                                            {notificationCount}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="px-2 py-3 border-b border-slate-100 flex items-center gap-2">
                                            <span className="text-base font-medium font-['Roboto', 'Arial', 'sans-serif']">{firstname}</span>
                                        </div>
                                    </div>
                                    {dropdownVisible && (
                                        <div className="w-full">
                                            <UserDropdown
                                                onLogout={() => {
                                                    doSignOut().then(() => {
                                                        navigate('/login');
                                                        localStorage.clear();
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                    {showDropdown && (
                                        <div className="relative mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg flex flex-col">
                                            <ul>
                                                {notificationMessages.map((message, index) => (
                                                    <li key={index} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                        {message}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start flex">
                                    {userLoggedIn && (
                                        <>
                                            <NavLink to={'/dashboard'} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                <HomeIcon className="w-6 h-6 relative " />
                                                <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                    {t('restaurants')}
                                                </span>
                                            </NavLink>
                                            {currentPath.startsWith('/restaurant-list-user') && (
                                                <>
                                                    <NavLink to={`/restaurant-list-user/device-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <DeviceTabletIcon className="w-6 h-6 relative " />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('devices')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list-user/transaction-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <CreditCardIcon className="w-6 h-6 relative " />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('transactions')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list-user/event-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <ClipboardListIcon className="w-6 h-6 relative" />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('events')}
                                                        </span>
                                                    </NavLink>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
            </nav>
            <div>
                {/* Content for mobile view also */}

                {isMobile && (
                    // Mobile : Admin Or User Not taken in consideration yet
                    <>

                        {isAdmin ? (
                            // Mobile Is Admin Header
                            <>
                                <div className="w-full  p-4">
                                    {showUsers && (
                                        <div className="self-stretch justify-start items-start gap-6 inline-flex">
                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                                <div className="self-stretch h-[26px] flex-col justify-start items-center gap-2 flex">
                                                    <div className="self-stretch text-sky-900 text-2xl font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-relaxed">Users</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {showGenerateR && (
                                        <div className="self-stretch justify-start items-start gap-6 inline-flex">
                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                                <div className="self-stretch h-[26px] flex-col justify-start items-center gap-2 flex">
                                                    <div className="self-stretch text-sky-900 text-2xl font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-relaxed">Dashboard</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="w-full gap-4 p-4 overflow-x-auto">
                                        <div className="self-stretch justify-start items-center gap-4 inline-flex ">
                                            <NavLink to={'/admin'} className="justify-start items-center gap-[5px] flex ">
                                                <HomeIcon className="w-6 h-6 relative " />
                                                <span className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">
                                                    {t('restaurants')}
                                                </span>
                                            </NavLink>
                                            {currentPath.startsWith('/restaurant-list') && (
                                                <>
                                                    <NavLink to={`/restaurant-list/web-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        {/* <div className="w-6 h-6 relative logoAddUser"></div> */}
                                                        <UsersIcon className="w-6 h-6 relative " />

                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('web_user')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list/device-admin/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <DeviceTabletIcon className="w-6 h-6 relative " />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('devices')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list/transaction-admin/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <CreditCardIcon className="w-6 h-6 relative " />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('transactions')}
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={`/restaurant-list/event-admin/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                        <ClipboardListIcon className="w-6 h-6 relative" />
                                                        <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                            {t('events')}
                                                        </span>
                                                    </NavLink>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="w-full  p-4">
                                {showUsers && (
                                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                            <div className="self-stretch h-[26px] flex-col justify-start items-center gap-2 flex">
                                                <div className="self-stretch text-sky-900 text-2xl font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-relaxed">Users</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {showGenerateR && (
                                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                            <div className="self-stretch h-[26px] flex-col justify-start items-center gap-2 flex">
                                                <div className="self-stretch text-sky-900 text-2xl font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-relaxed">Dashboard</div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="w-full gap-4 p-4 overflow-x-auto">
                                    <div className="self-stretch justify-start items-center gap-4 inline-flex ">
                                        <NavLink to={'/dashboard'} className="justify-start items-center gap-[5px] flex ">
                                            <HomeIcon className="w-6 h-6 relative " />
                                            <span className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">
                                                {t('restaurants')}
                                            </span>
                                        </NavLink>
                                        {currentPath.startsWith('/restaurant-list-user') && (
                                            <>
                                                <NavLink to={`/restaurant-list-user/device-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                    <DeviceTabletIcon className="w-6 h-6 relative " />
                                                    <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                        {t('devices')}
                                                    </span>
                                                </NavLink>
                                                <NavLink to={`/restaurant-list-user/transaction-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                    <CreditCardIcon className="w-6 h-6 relative " />
                                                    <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                        {t('transactions')}
                                                    </span>
                                                </NavLink>
                                                <NavLink to={`/restaurant-list-user/event-user/${cloverMID}`} className={({ isActive }) => `self-stretch px-2 py-3 border-b border-slate-100 justify-start items-center gap-2 inline-flex ${isActive ? 'bg-slate-100 text-sky-900' : 'text-gray-900'}`}>
                                                    <ClipboardListIcon className="w-6 h-6 relative" />
                                                    <span className="grow shrink basis-0 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none">
                                                        {t('events')}
                                                    </span>
                                                </NavLink>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Header
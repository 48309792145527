// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {

    apiKey: "AIzaSyCRCVycq2BxNCCQYOQ_c6lHjB-kruSnKJI",
    authDomain: "fluted-reason-405616.firebaseapp.com",
    databaseURL: "https://fluted-reason-405616-default-rtdb.firebaseio.com",
    projectId: "fluted-reason-405616",
    storageBucket: "fluted-reason-405616.appspot.com",
    messagingSenderId: "96502804876",
    appId: "1:96502804876:web:4fc51cbdc12543f0f026a9",
    measurementId: "G-8NEH572J00"
  
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const perf = getPerformance(app);

export { app, auth, perf, firestore };
export default firestore;
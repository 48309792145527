import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react';
import Header from "../header";
import { useTranslation } from 'react-i18next';

import {
    query,
    getDocs,
    collection,
    where,
} from 'firebase/firestore';
import db from '../../firebase/firebase';

import { useAuth } from "../../contexts/authContext";
import { useParams, useNavigate } from 'react-router-dom';

function TableComponent() {
    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')
    if(currentUserRole !== 'admin'){
        navigate('/accessDenied', { replace: true }); 
    }
    const { currentUser } = useAuth();
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(currentUser !== null);  // New state for logged in message
    const [remainingDays, setRemainingDays] = useState(7);
    const today = new Date();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - today.getDay());
    const [users, setUsers] = useState();
    const [cloverIds, setCloverIds] = useState([]);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date());
    const [lastDayOfMonth, setLastDayOfMonth] = useState(new Date());
    const [isMobile, setIsMobile] = useState(false);
    const { cloverMID } = useParams();
    const restaurantsData = JSON.parse(localStorage.getItem("mevMandatarysData"))
    const restaurantIndex = restaurantsData.findIndex(item => item.cloverMerchantId === cloverMID);
    const selectedRestaurant = restaurantIndex !== -1 ? restaurantsData[restaurantIndex] : { businessName: '' };
    const [data, setData] = useState([]);
    const { t } = useTranslation();

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if ( selectedRestaurant.businessName !== "") {
            // document.title = `${selectedRestaurant.businessName}`;
            localStorage.setItem('lastValidPath', window.location.pathname);
        }else{
            navigate('/error404')
        }
    }, [selectedRestaurant, navigate]);
    
    const getUsers = async () => {
        try {
            let isLoggedIn = false
            if (!localStorage.getItem('lookupData-' + cloverMID)) {
                // const querySnapshot = await getDocs(query(collection(db, "Lookup"), where('cloverId', '==', cloverMID)));
                // console.log("Lookup fetched from Firestore:", querySnapshot.docs.length);
                const querySnapshot = await getDocs(query(collection(db, "Users"), where('merchantId', '==', cloverMID)));
                console.log("UserAccounts fetched from Firestore:", querySnapshot.docs.length);

                const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                localStorage.setItem('lookupData-' + cloverMID, JSON.stringify(usersData));
               
                const uniqueCloverIds = [...new Set(usersData.map(user => user.cloverId))];
                setCloverIds(uniqueCloverIds);

                const defaultStatusUsers = usersData.filter(user => user.status === "default");

                if (defaultStatusUsers.length > 0) {
                    defaultStatusUsers.forEach(user => {
                        const createdAtTimestamp = user.createdAt.toDate(); // Convert Firestore Timestamp to JavaScript Date object
                        const currentDate = new Date();
                        const diffTime = Math.abs(currentDate - createdAtTimestamp);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        const remainingDays = 7 - diffDays;
                        setRemainingDays(remainingDays);
                    });
                    isLoggedIn = true;
                }
            }
            setIsLoggedInMessage(!isLoggedIn);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        const cachedUsersData = localStorage.getItem('lookupData-' + cloverMID);
        const usersData = JSON.parse(cachedUsersData);

        // Array to store all cloverIds
        const cloverIds = [];

        if (cachedUsersData ) {
            usersData.forEach(user => {
                cloverIds.push(user.cloverId);
            });
            let isLoggedIn = false

            setCloverIds(cloverIds)
            setUsers(JSON.parse(cachedUsersData));

            const defaultStatusUsers = usersData.filter(user => user.status === "default");

            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {

                    if (user.createdAt && typeof user.createdAt.seconds === 'number') {
                        // Convert seconds to milliseconds and nanoseconds to milliseconds
                        const milliseconds = user.createdAt.seconds * 1000 + Math.floor(user.createdAt.nanoseconds / 1000000);
                        const createdAtDate = new Date(milliseconds);
                        const createdAtTimestamp = createdAtDate; // Convert Firestore Timestamp to JavaScript Date object
                        const currentDate = new Date();
                        const diffTime = Math.abs(currentDate - createdAtTimestamp);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        const remainingDays = 7 - diffDays;
                        setRemainingDays(remainingDays);
                    } else {
                        console.log('Invalid createdAt format');
                    }
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);
        } else {
            getUsers();
        }
    }, []);

    useEffect(() => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setFirstDayOfMonth(firstDay);
        setLastDayOfMonth(lastDay);
    }, []);

    // useEffect(() => {
    //     const handleTouchMove = (e) => {
    //         e.preventDefault(); // Prevent scrolling
    //     };
    //     document.body.style.overflow = 'hidden'; // Disable scrolling
    //     document.addEventListener('touchmove', handleTouchMove, { passive: false });

    //     return () => {
    //         document.body.style.overflow = ''; // Enable scrolling
    //         document.removeEventListener('touchmove', handleTouchMove);
    //     };
    // }, []);

    useEffect(() => {
        // Function to fetch BusinessNames from MEVMandatorys collection
        const fetchBusinessNames = async () => {
            try {
                const mevMandatarysData = localStorage.getItem('mevMandatarysData');
                if (!mevMandatarysData) {
                    console.error('No MEVMandatarys data found in localStorage');
                    return;
                }
                const parsedData = JSON.parse(mevMandatarysData);
                const result = parsedData.filter(item => item.businessName === selectedRestaurant.businessName);

                if (result.length > 0) {
                    const formattedResult = formatDates(result[0]);
                    setData(formattedResult);
                } else {
                    console.error('No matching business name found');
                }
            } catch (error) {
                console.error('Error fetching business names:', error);
            }
        };
        fetchBusinessNames();
        // Clean-up function to unsubscribe when component unmounts
        return () => { };
    }, [selectedRestaurant.businessName]);

    const formatDates = (data) => {
        const formattedData = { ...data };
        if (data.createdDate) {
            formattedData.createdDate = new Date(data.createdDate.seconds * 1000).toLocaleDateString();
        }
        if (data.lastModifiedDate) {
            formattedData.lastModifiedDate = new Date(data.lastModifiedDate.seconds * 1000).toLocaleDateString();
        }
        return formattedData;
    };

    // Your table JSX
    return (
        <Fragment>
            {isMobile && (
                <div className="w-full h-full bg-stone-50 flex-col justify-start items-start gap-4 inline-flex">

                    <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={true} />
                    <div className="w-full p-4 overflow-x-auto">
                        <div className="bg-white shadow-lg rounded-lg">

                            <h1 className="text-2xl font-bold mb-4 text-center">{t('restaurant_information')}</h1>

                            <ul className="divide-y divide-gray-200">
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('activity_sub_sector')}:</span> {selectedRestaurant.activitySubSector}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('authorization_code')}:</span> {selectedRestaurant.authorizationCode}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('billing_file_number')}:</span> {selectedRestaurant.billingFileNo}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('business_name')}:</span> {selectedRestaurant.businessName}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('city')}:</span> {selectedRestaurant.city}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('clover_merchant_id')}:</span> {selectedRestaurant.cloverMerchantId}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('created_date')}:</span> {new Date(selectedRestaurant.createdDate.seconds * 1000).toLocaleDateString()}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('identification_number')}:</span> {selectedRestaurant.identificationNo}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('last_modified_date')}:</span> {new Date(selectedRestaurant.lastModifiedDate.seconds * 1000).toLocaleDateString()}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('phone_number')}:</span> {selectedRestaurant.phone}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('postal_code')}:</span> {selectedRestaurant.postalCode}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('province')}:</span> {selectedRestaurant.province}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('status')}:</span> <span className={`font-semibold ${selectedRestaurant.status ? 'text-green-500' : 'text-red-500'}`}>{selectedRestaurant.status ? t('active') : t('not_active')}</span>
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('street')} 1:</span> {selectedRestaurant.street1}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('street')} 2:</span> {selectedRestaurant.street2}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('tps_number')}:</span> {selectedRestaurant.tpsNo}
                                </li>
                                <li className="py-2">
                                    <span className="px-2 font-semibold">{t('tvq_number')}:</span> {selectedRestaurant.tvqNo}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {/* Content for desktop view */}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex  overflow-x-auto ">
                        <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={true} />
                        <div className="w-full h-[794px] p-6 flex-col justify-start items-start gap-16 inline-flex " style={{ paddingLeft: '17.5rem' }}>
                            <div className="w-full h-12 relative">
                                <div className="h-[46px] left-0 top-0 absolute flex-col justify-start items-start gap-12 inline-flex">
                                    <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                                        <div className="self-stretch text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{selectedRestaurant.businessName}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full p-6 bg-white shadow-lg rounded-lg">
                                <h1 className="text-3xl font-bold mb-6 text-center">{t('restaurant_information')}</h1>
                                <ul className="divide-y divide-gray-200">
                                    <li className="py-4">
                                        <span className="font-semibold">{t('activity_sub_sector')}:</span> {selectedRestaurant.activitySubSector}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('authorization_code')}:</span> {selectedRestaurant.authorizationCode}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('billing_file_number')}:</span> {selectedRestaurant.billingFileNo}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('business_name')}:</span> {selectedRestaurant.businessName}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('city')}:</span> {selectedRestaurant.city}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('clover_merchant_id')}:</span> {selectedRestaurant.cloverMerchantId}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('created_date')}:</span> {data.createdDate}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('identification_number')}:</span> {selectedRestaurant.identificationNo}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('last_modified_date')}:</span> {data.lastModifiedDate}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('phone_number')}:</span> {selectedRestaurant.phone}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('postal_code')}:</span> {selectedRestaurant.postalCode}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('province')}:</span> {selectedRestaurant.province}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('status')}:</span> <span className={`font-semibold ${selectedRestaurant.status ? 'text-green-500' : 'text-red-500'}`}>{selectedRestaurant.status ? t('active') : t('not_active')}</span>
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('street')} 1:</span> {selectedRestaurant.street1}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('street')} 2:</span> {selectedRestaurant.street2}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('tps_number')}:</span> {selectedRestaurant.tpsNo}
                                    </li>
                                    <li className="py-4">
                                        <span className="font-semibold">{t('tvq_number')}:</span> {selectedRestaurant.tvqNo}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default TableComponent;

import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    doc,
    updateDoc,
} from 'firebase/firestore';
import db from '../../firebase/firebase';

// Utility function to format timestamp
const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return '';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString(); // Format date as needed
};

const EditStatusForm = ({ user, onClose, onUpdate }) => {
    const [status, setStatus] = useState(user.status);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        const currentDate = new Date();

        // const day = currentDate.getDate();
        // const month = currentDate.toLocaleString('en-US', { month: 'long' });
        // const year = currentDate.getFullYear();
        // const hours = String(currentDate.getHours()).padStart(2, '0');
        // const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        // const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        // const timeZoneOffset = currentDate.getTimezoneOffset();
        // const absoluteOffset = Math.abs(timeZoneOffset);
        // const offsetHours = String(Math.floor(absoluteOffset / 60)).padStart(2, '0');
        // const offsetMinutes = String(absoluteOffset % 60).padStart(2, '0');
        // const offsetSign = timeZoneOffset > 0 ? '-' : '+';
        // const formattedOffset = `UTC${offsetSign}${offsetHours}:${offsetMinutes}`;

        // const formattedDate = `${day} ${month} ${year} at ${hours}:${minutes}:${seconds} ${formattedOffset}`;


        try {
            // Update the user's status in Firestore
            const userRef = doc(db, "MEVMandatarys", user.id);
            await updateDoc(userRef, { 
                status, 
                lastModifiedDate: currentDate 
            });
            onUpdate(user.id, status);
            onClose();
        } catch (err) {
            setError('Failed to update status. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const { t } = useTranslation();
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded">
                <h2 className="text-xl mb-4">{t('edit_status_for')} {user.firstname} {user.lastname}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2">{t('status')}</label>
                        <select
                            className="border rounded w-full py-2 px-3"
                            value={status.toString()}
                            onChange={(e) => setStatus(e.target.value)}
                            disabled={loading}
                        >
                            <option value="true">{t('active')}</option>
                            <option value="default">{t('default')}</option>
                            <option value="false">{t('not_active')}</option>

                        </select>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="flex justify-end">
                        <button type="button" className="mr-4 px-4 py-2 bg-gray-300 rounded" onClick={onClose} disabled={loading}>Cancel</button>
                        <button type="submit" className="px-4 py-2 bg-sky-900 text-white rounded" disabled={loading}>
                            {loading ? t('saving') : t('save')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RestaurantTable = ({ mevMandatarysData, selectedBusinessName }) => {
    const [sortOrder, setSortOrder] = useState('asc'); 
    const [sortedColumn, setSortedColumn] = useState('createdDate'); 
    const [selectedUser, setSelectedUser] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([]);

    React.useEffect(() => {
        if (mevMandatarysData && mevMandatarysData.length > 0) {
            // setFilteredUsers(users.filter(user => user.cloverId === cloverMID));
            setFilteredUsers(mevMandatarysData);
        }
    }, [mevMandatarysData]);

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const sortByCreatedDate = () => {
        toggleSortOrder();
        setSortedColumn('createdDate');
    };

    const sortData = (data) => {
        const sortedData = [...data].sort((a, b) => {
            const dateA = a.createdDate.seconds;
            const dateB = b.createdDate.seconds;
            if (sortOrder === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        return sortedData;
    };

    const filteredData = selectedBusinessName === "Restaurants" ? mevMandatarysData : mevMandatarysData.filter(restaurant => restaurant.businessName === selectedBusinessName);
    const sortedData = sortData(filteredData);
    const { t } = useTranslation();

    const handleStatusClick = (user) => {
        setSelectedUser(user);
    };
    const handleCloseForm = () => {
        setSelectedUser(null);
    };
    const handleUpdateStatus = (userId, newStatus) => {
        const updatedUsers = filteredUsers.map(user =>
            user.id === userId ? { ...user, status: newStatus } : user
        );
        setFilteredUsers(updatedUsers);
    };

    return (
        <div className="w-full h-full px-8 py-6 bg-white flex-col justify-start items-start inline-flex">
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        <th className="px-4 py-2">{t('restaurant_name')}</th>
                        <th className="px-4 py-2">MID</th>
                        <th className="px-4 py-2">{t('address')}</th>
                        <th className="px-4 py-2">{t('access_code')}</th>
                        <th className="px-4 py-2">{t('gst_number')}</th>
                        <th className="px-4 py-2">{t('tvq_number')}</th>
                        <th className="px-4 py-2 cursor-pointer" onClick={sortByCreatedDate}>{t('created_date')}</th>
                        <th className="px-4 py-2">{t('status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((restaurant, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                            <td className="border px-4 py-2">
                                <NavLink to={`/restaurant-list/${restaurant.cloverMerchantId}`} className="hover:underline">
                                    {restaurant.businessName}
                                </NavLink>
                            </td>
                            <td className="border px-4 py-2">{restaurant.cloverMerchantId}</td>
                            <td className="border px-4 py-2">{`${restaurant.street1}, ${restaurant.street2}, ${restaurant.city}, ${restaurant.province}, ${restaurant.postalCode}`}</td>
                            <td className="border px-4 py-2">{restaurant.authorizationCode}</td>
                            <td className="border px-4 py-2">{restaurant.tpsNo}</td>
                            <td className="border px-4 py-2">{restaurant.tvqNo}</td>
                            <td className={`border px-4 py-2 font-semibold ${restaurant.status ? 'text-green-500' : 'text-yellow-500'}`}>
                                {formatDate(restaurant.createdDate)}
                            </td>
                           
                            <td
                              className={`border px-4 py-2 font-semibold cursor-pointer ${
                                restaurant.status === true || restaurant.status === "true" ? 'text-green-500' :
                                restaurant.status === false || restaurant.status === "false" ? 'text-red-500' :
                                'text-yellow-500'
                            }`} 
                                onClick={() => handleStatusClick(restaurant)}
                            >
                                {restaurant.status === true || restaurant.status === "true"
                                ? t('active')
                                : restaurant.status === "default" 
                                ? t('default')
                                : t('not_active')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedUser && (
                <EditStatusForm
                    user={selectedUser}
                    onClose={handleCloseForm}
                    onUpdate={handleUpdateStatus}
                />
            )}
        </div>
    );
};

export default RestaurantTable;

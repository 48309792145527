import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Utility function to format timestamp
const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return '';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString(); // Format date as needed
};


const RestaurantTable = ({ mevMandatarysData, selectedBusinessName }) => {
    const [sortOrder, setSortOrder] = useState('asc'); 
    const [sortedColumn, setSortedColumn] = useState('createdDate'); 

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const sortByCreatedDate = () => {
        toggleSortOrder();
        setSortedColumn('createdDate');
    };

    const sortData = (data) => {
        const sortedData = [...data].sort((a, b) => {
            const dateA = a.createdDate.seconds;
            const dateB = b.createdDate.seconds;
            if (sortOrder === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        return sortedData;
    };

    const filteredData = selectedBusinessName === "Restaurants" ? mevMandatarysData : mevMandatarysData.filter(restaurant => restaurant.businessName === selectedBusinessName);
    const sortedData = sortData(filteredData);
    const { t } = useTranslation();

    return (
        <div className="w-full h-full px-8 py-6  flex-col justify-start items-start inline-flex">
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        <th className="px-4 py-2">{t('restaurant_name')}</th>
                        <th className="px-4 py-2">MID</th>
                        <th className="px-4 py-2">{t('address')}</th>
                        <th className="px-4 py-2">{t('access_code')}</th>
                        <th className="px-4 py-2">{t('gst_number')}</th>
                        <th className="px-4 py-2">{t('tvq_number')}</th>
                        <th className="px-4 py-2 cursor-pointer" onClick={sortByCreatedDate}>{t('created_date')}</th>
                        <th className="px-4 py-2">{t('status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((restaurant, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                            <td className="border px-4 py-2">
                                <NavLink to={`/restaurant-list-user/${restaurant.cloverMerchantId}`} className="hover:underline">
                                    {restaurant.businessName}
                                </NavLink>
                            </td>
                            <td className="border px-4 py-2">{restaurant.cloverMerchantId}</td>
                            <td className="border px-4 py-2">{`${restaurant.street1}, ${restaurant.street2}, ${restaurant.city}, ${restaurant.province}, ${restaurant.postalCode}`}</td>
                            <td className="border px-4 py-2">{restaurant.authorizationCode}</td>
                            <td className="border px-4 py-2">{restaurant.tpsNo}</td>
                            <td className="border px-4 py-2">{restaurant.tvqNo}</td>
                            <td className={`border px-4 py-2 font-semibold ${restaurant.status ? 'text-green-500' : 'text-yellow-500'}`}>
                                {formatDate(restaurant.createdDate)}
                            </td>
                            <td
                              className={`border px-4 py-2 font-semibold ${
                                restaurant.status === true || restaurant.status === "true" ? 'text-green-500' :
                                restaurant.status === false || restaurant.status === "false" ? 'text-red-500' :
                                'text-yellow-500'
                            }`} 
                            >
                                {restaurant.status === true || restaurant.status === "true"
                                ? t('active')
                                : restaurant.status === "default" 
                                ? t('default')
                                : t('not_active')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RestaurantTable;
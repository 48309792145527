import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
    const { t } = useTranslation();

    const goBack = () => {
        window.history.back();
    };
    return (
        <div className="w-full h-full relative bg-sky-900 backgroundLoginDark">
            <div className="w-[80%] sm:w-[70%] lg:w-[60%] xl:w-[50%] h-[263px] p-20 sm:p-10 lg:p-20 xl:p-40 absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-2xl border border-zinc-200 flex flex-col justify-center items-center gap-6">
                <div className="h-[103px] pb-6 flex flex-col justify-center items-center gap-2">
                    <div className="text-center text-sky-900 text-[42px] font-bold leading-[46.20px] sm:text-2xl lg:text-3xl xl:text-4xl">{t('access_denied')}</div>
                    <div className="text-center text-zinc-800 text-lg font-normal leading-[25.20px] sm:text-base lg:text-lg xl:text-xl">{t('msg_page_unaccessable')}</div>
                    <div className="text-center text-sky-900 text-sm font-normal leading-tight">
                        <button onClick={goBack} className="hover:underline font-bold">{t('go_back')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;

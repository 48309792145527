import Login from "./components/auth/login";
import Register from "./components/auth/register";
import PasswordReset from "./components/auth/reset";
import EmailVerification from "./components/auth/verificationEmail";
import SetUpPassword from "./components/auth/setUpPassword";
import Header from "./components/header";
import Home from "./components/home";
import TableComponent from "./components/report-table";

import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import TableComponentUser from "./components/report-table-user";
import AdminHomepage from "./components/adminHomepage";
import UserHomepage from "./components/userHomepage";
import CustomPasswordReset from "./components/auth/reset/CustomPasswordReset";
import VerifyingEmail from "./components/auth/emailVerified";
import EventAdmin from "./components/events/eventsAdmin";
import DeviceAdmin from "./components/devices/deviceAdmin";
import TransactionAdmin from "./components/transactions/transactionAdmin";
import EventUser from "./components/events/eventsUser";
import DeviceUser from "./components/devices/deviceUser";
import TransactionUser from "./components/transactions/transactionUser";
import AuthAction from "./components/auth/authAction";

// import ProtectedRoute from "./components/ProtectedRoutes";
import { useState } from 'react';
import AccessDenied from "./components/accessDenied";
import Error404 from "./components/error404";


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Assuming you have a way to determine if the user is logged in and their role
  const userIsAdmin = false; // Set this based on the user's role
  const userLoggedIn = true; // Set this based on whether the user is logged in or not

  const updateStatus = () => {
    setIsLoggedIn(prev => !prev);
  };
  
  const routesArray = [
    {
      path: "*",
      element: <Error404 />,
    },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/reset",
      element: <PasswordReset />,
    },
    {
      path: "/customReset",
      element: <CustomPasswordReset />,
    },
    {
      path: "/customReset",
      element: <CustomPasswordReset />,
    },
    {
      path: "/emailVerification",
      element: <EmailVerification />,
    },
    {
      path: "/password-setup",
      element: <SetUpPassword />,
    },
    {
      path: "/emailVerified",
      element: <VerifyingEmail />,
    },
    
    {
      path: "/admin",
      element:<AdminHomepage />
    },
    {
      path: "/action",
      element:<AuthAction />
    },
    {
      path: "/dashboard",
      element:<UserHomepage />
    },
    {
      path: "/restaurant-list/:cloverMID",
      element: <TableComponent  />,
    },
    {
      path: "/restaurant-list/web-user/:cloverMID",
      element: <Home />,
    },
    {
      path: "restaurant-list/event-admin/:cloverMID",
      element: <EventAdmin  />,
    },
    {
      path: "restaurant-list/device-admin/:cloverMID",
      element: <DeviceAdmin  />,
    },
    {
      path: "restaurant-list/transaction-admin/:cloverMID",
      element: <TransactionAdmin  />,
    },
    {
      path: "/restaurant-list-user/:cloverMID",
      element: <TableComponentUser  />,
    },
    {
      path: "/restaurant-list-user/event-user/:cloverMID",
      element: <EventUser  />,
    },
    {
      path: "/restaurant-list-user/device-user/:cloverMID",
      element: <DeviceUser  />,
    },
    {
      path: "/restaurant-list-user/transaction-user/:cloverMID",
      element: <TransactionUser  />,
    },
    {
      path: "/accessDenied",
      element: <AccessDenied  />,
    },
    {
      path: "/error404",
      element: <Error404  />,
    }
    // {
    //   path: "/home",
    //   element: <ProtectedRoute element={<Home />} />,
    // },
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      {/* <Header /> */}
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { doSendEmailVerification } from "../../../firebase/auth";
import { useTranslation } from 'react-i18next';

const EmailVerification = () => {
    const location = useLocation();
    const [resending, setResending] = useState(false); // State to handle the resending process   
    const { t } = useTranslation();

    const handleResendVerification = async () => {
        try {
            setResending(true); // Set resending state to true to indicate the process has started
            await doSendEmailVerification(location.state.email); // Assuming doSendEmailVerification is an asynchronous function
        } catch (error) {
            console.error("Error resending verification email:", error);
        } finally {
            setResending(false); // Set resending state back to false regardless of success or failure
        }
    };

    return (
        <div className="w-full h-screen relative bg-neutral-50 backgroundLogin">
            <div className="mx-auto max-w-lg mt-20 p-8 bg-white rounded-2xl border border-zinc-200 flex flex-col justify-center items-center gap-6">
                <div className="text-center">
                    <div className="text-sky-900 text-3xl font-bold">{t('verify_your_email')}</div>
                    <div className="text-zinc-800 text-lg mt-4">{t('msg_verification_email_sent_email')}</div>
                </div>
                <div className="border border-zinc-200 w-full my-4"></div>
                <div className="text-center text-sky-900 text-sm font-normal">
                    Already verified?{'   '}
                    {/* {location.state?.email && <p>Email: {location.state.email}</p>} */}
                    {/* Link to resend verification email */}
                    <button onClick={handleResendVerification} disabled={resending} className="text-sm hover:underline font-bold">
                        {resending ? t('resending') : t('resend_verification_email')}
                    </button>
                </div>
            </div>
        </div>
    );
};


export default EmailVerification;